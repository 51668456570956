import Axios from "@/plugins/Axios";
import store from "@/store";
import Turno from "@/Models/Turno";

export class TurnosService {
  async syncAll() {
    const response = await Axios().get("turnos");
    const turnos = response.data.map((curso) => new Turno(curso));
    return turnos;
  }

  async criarTurno(turno) {
    const response = await Axios().post("turnos", turno);
    const novaTurno = response.data;
    store.commit("Turnos/adicionarTurno", novaTurno);
    return novaTurno;
  }

  async atualizarTurno(turno) {
    const response = await Axios().put(`turnos/${turno.id}`, turno);
    const turnoAtualizada = response.data;
    store.commit("Turnos/atualizarTurno", turnoAtualizada);
    return turnoAtualizada;
  }

  async deletarTurno(turno) {
    const response = await Axios().delete(`turnos/${turno.id}`);
    const turnoDeletada = response.data;
    store.commit("Turnos/deletarTurno", turno.id);
    return turnoDeletada;
  }

  async recuperarTurno(turno) {
    return this.atualizarTurno({ ...turno, deleted_at: null });
  }
}
export default new TurnosService();
