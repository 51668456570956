import formasDePagamentoService from "@/Services/FormasDePagamentoService";

const state = () => ({
  formasDePagamento: [],
  carregandoFormasDePagamento: false,
});
const mutations = {
  setFormasDePagamento(state, formasDePagamento) {
    state.formasDePagamento = formasDePagamento;
  },
  setCarregandoFormasDePagamento(state, newValue) {
    state.carregandoFormasDePagamento = newValue;
  },
};
const actions = {
  syncFormasDePagamento: async ({ commit }) => {
    commit("setCarregandoFormasDePagamento", true);
    const formasDePagamento = await formasDePagamentoService.syncAll();
    commit("setFormasDePagamento", formasDePagamento);
    commit("setCarregandoFormasDePagamento", false);
  },
};
const getters = {
  formasDePagamento: (state) => state.formasDePagamento,
  carregandoFormasDePagamento: (state) => state.carregandoFormasDePagamento,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
