import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
  setInteractionMode,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize({
  pt_BR,
});

const timeValidator = (value) => {
  if (value.length < 5) return false;
  const [horas, minutos] = value.split(":").map((val) => parseInt(val, 10));
  return horas < 24 && minutos < 60;
};

extend("timeValidator", timeValidator);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
localize("pt_BR");
setInteractionMode("eager");
