<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title class="text-h5"> Aula #{{ aula.id }} </v-card-title>

        <v-card-text v-if="aula.professor && aula.professor.nome">
          <div :class="[`text-button`, `font-weight-black`]" v-text="`Professor(a):`"></div>
          <div :class="[`text-subtitle-2`]">
            <span class="mr-3">{{ aula.professor.nome }}</span>
          </div>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="12" lg="4" sm="3" md="3" xs="3">
              <div :class="[`text-button`, `font-weight-black`]" v-text="`Tipo de aula:`"></div>
              <div :class="[`text-subtitle-2`]" v-text="aula.tipo_de_aula"></div>
            </v-col>
            <v-col cols="12" lg="4" sm="4" md="4" xs="4">
              <div :class="[`text-button`, `font-weight-black`]" v-text="`Data:`"></div>
              <div :class="[`text-subtitle-2`]">
                <span>{{ aula.data_formatada }}</span>
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              sm="4"
              md="4"
              xs="4"
              v-if="aula.horario_inicial || aula.horario_final"
            >
              <div :class="[`text-button`, `font-weight-black`]" v-text="`Horários:`"></div>
              <div :class="[`text-subtitle-2`]">
                <span class="mr-3">{{ aula.horario_inicial }}</span>
                <span class="ml-3">{{ aula.horario_final }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <div :class="[`text-button`, `font-weight-black`]" v-text="`Conteúdo:`"></div>
          <div :class="[`text-subtitle-2`]">
            <span class="mr-3">{{ aula.conteudo }}</span>
          </div>
        </v-card-text>

        <v-card-text v-if="aula.objetivos_ou_habilidades">
          <div
            :class="[`text-button`, `font-weight-black`]"
            v-text="`Objetivos/Habilidades:`"
          ></div>
          <div :class="[`text-subtitle-2`]">
            <span class="mr-3">{{ aula.objetivos_ou_habilidades }}</span>
          </div>
        </v-card-text>

        <v-card-text>
          <div :class="[`text-button`, `font-weight-black`]" v-text="`Metodologia:`"></div>
          <div :class="[`text-subtitle-2`]">
            <span class="mr-3">{{ aula.metodologia }}</span>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('dialogChange', false)"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    aula: {
      type: Object,
      require: true,
    },
  },
  methodo: {},
};
</script>
