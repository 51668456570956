<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="ma-2"
        style="width: 80px; justify-content: center"
        color="primary"
        outlined
        label
        small
        v-bind="attrs"
        v-on="on"
      >
        {{ tipo }}
      </v-chip>
    </template>
    <span>
      <div :key="item.id" v-for="item in items">
        {{ item.descricao }}
      </div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    tipo: {
      type: String,
      required: "- - -",
    },
  },
};
</script>

<style></style>
