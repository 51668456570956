import FranquiasIndexPage from "@/Pages/Franquias/index.vue";
import NewEditFranquiaPage from "@/Pages/Franquias/NewEditFranquiaPage.vue";

export default [
  {
    path: "/franquias",
    name: "franquias",
    component: FranquiasIndexPage,
  },
  {
    path: "/franquias/create",
    name: "franquias.create",
    component: NewEditFranquiaPage,
    props: { editing: false },
  },
  {
    path: "/franquias/edit/:franquia_id",
    name: "franquias.edit",
    component: NewEditFranquiaPage,
    props: { editing: true },
  },
];
