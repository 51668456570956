import Axios from "@/plugins/Axios";
// import store from "@/store";

export class FormasDePagamentoService {
  async syncAll() {
    if (this.syncAll.formasDePagamento) return this.syncAll.formasDePagamento;
    const response = await Axios().get("formas-de-pagamento");
    const formasDePagamento = response.data;
    // store.commit("FormasDePagamento/setFormasDePagamento", formasDePagamento);
    this.syncAll.formasDePagamento = formasDePagamento;
    return formasDePagamento;
  }
}
export default new FormasDePagamentoService();
