<template>
  <v-row>
    <v-col>
      <v-card>
        <LineChartGenerator
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        <v-card-actions>
          <v-btn color="orange lighten-2" text> Método de Avaliação </v-btn>

          <v-spacer></v-spacer>

          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="!show">
            <v-divider></v-divider>

            <v-card-text>
              O gráfico demonstra, de modo simples, a quantidade de faltas e presenças por mês.
              Nesse contexto, todo o processo é calculado pela quantidade de aulas realizadas na
              turma em que o(a) aluno(a) está matriculado(a).
              <br />
              <br />
              Obs: deixe o cursor em cima do ponto - laranja ou azul para obter a quantidade.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  watch: {
    data(value) {
      this.chartData.labels = value.meses;
      this.chartData.datasets = [value.faltas, value.presencas];
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 260,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      chartData: {
        labels: this.data.meses,
        datasets: [
          {
            label: "Data One",
            data: [this.data.faltas, this.data.presencas],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        backgroundColor: ["#FF0000"],
        maintainAspectRatio: false,
      },
    };
  },
  methods: {},
};
</script>
