/* eslint-disable no-param-reassign */
import store from "@/store";
import User from "@/Models/User";

function can(permissions) {
  if (permissions === "" || !permissions) return true;
  const user = store.getters["Auth/user"];
  if (!user) return false;
  const userInstance = new User({ ...user });
  if (typeof permissions === "string") {
    const permission = permissions;
    return userInstance.hasPermission(permission);
  }
  if (!Array.isArray(permissions)) return false;
  const permissionsNeededUserHas = permissions.filter((permission) => userInstance.can(permission));
  const userHasAtLeastOnePermission = permissionsNeededUserHas.length > 0;
  return userHasAtLeastOnePermission;
}
export default (vue) => {
  vue.prototype.$can = can;
};
