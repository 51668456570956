<template>
  <v-card
    v-on="trocarDeFranquiaAoClicar ? { click: handleClick } : {}"
    class="d-flex flex-column"
    v-bind:class="{ 'animate__animated animate__fadeIn': animated }"
  >
    <v-card-title style="word-break: break-word; text-align: center" v-html="franquia.descricao" />
    <v-card-text style="margin-top: auto">
      <div>
        <strong>Qtd. turmas:</strong> {{ franquia.qtdTurmas }}
        <v-tooltip bottom color="#333">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="franquia.qtdTurmas == 0" x-small color="warning" v-bind="attrs" v-on="on"
              >fa fa-exclamation-triangle</v-icon
            >
          </template>
          <span>Esta unidade escolar não tem turmas cadastradas</span>
        </v-tooltip>
      </div>
      <div>
        <strong>Qtd. alunos:</strong> {{ franquia.qtdAlunos }}
        <v-tooltip bottom color="#333">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="franquia.qtdAlunos == 0" x-small color="warning" v-bind="attrs" v-on="on"
              >fa fa-exclamation-triangle</v-icon
            >
          </template>
          <span>Esta unidade escolar não tem alunos cadastrados</span>
        </v-tooltip>
      </div>
      <div>
        <strong>Qtd. matriculas:</strong> {{ franquia.qtdMatriculas }}
        <v-tooltip bottom color="#333">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="franquia.qtdMatriculas == 0"
              x-small
              color="warning"
              v-bind="attrs"
              v-on="on"
              >fa fa-exclamation-triangle</v-icon
            >
          </template>
          <span>Esta unidade escolar não tem matrículas cadastradas</span>
        </v-tooltip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    franquia: {
      type: Object,
      required: true,
    },
    animated: {
      type: Boolean,
    },
    trocarDeFranquiaAoClicar: {
      type: Boolean,
    },
  },
  methods: {
    async handleClick() {
      this.$loaderService.open("Trocando Unidade escolar");
      try {
        await this.$services.authService.mudarDeFranquia(this.franquia);
        this.$toast.success("Unidade escolar alterada com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style scoped></style>
