<template>
  <main-template>
    <v-container>
      <v-row>
        <v-col class="d-flex align-center">
          <v-btn color="primary" x-small text @click="goBack" :disabled="baixandoParcela">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          <span class="text-h5">Baixando parcela</span>
        </v-col>
      </v-row>
      <v-row v-if="parcelaSelecionada">
        <v-col>
          <e-snippet-matricula :matricula="matriculaSelecionada" />
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nº</th>
                  <th class="text-left">Referência</th>
                  <th class="text-left">Valor</th>
                  <th class="text-left">Valor Pago</th>
                  <th class="text-left">Valor Em Aberto</th>
                  <th class="text-left">Desconto</th>
                  <th class="text-left">Juros</th>
                  <th class="text-left">Multa</th>
                  <th class="text-left">Situação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ parcelaSelecionada.numero }}</td>
                  <td>{{ parcelaSelecionada.referencia }}</td>
                  <td>{{ parcelaSelecionada.valor | currency }}</td>
                  <td>{{ parcelaSelecionada.valorpago | currency }}</td>
                  <td>
                    {{ parcelaSelecionada.valorEmAberto | currency }}
                  </td>
                  <td>{{ parcelaSelecionada.desconto | currency }}</td>
                  <td>{{ parcelaSelecionada.juros | currency }}</td>
                  <td>{{ parcelaSelecionada.multa | currency }}</td>
                  <td>
                    <e-situacao-parcela :parcela="parcelaSelecionada" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row v-if="form">
        <v-col>
          <form :disabled="baixandoParcela">
            <div class="d-flex">
              <v-currency-field
                v-model="form.juros"
                dense
                class="mr-1"
                outlined
                label="Juros"
                :background-color="
                  valorTotalSendoPago > valorEmAberto && valorTotalSendoPago > 0
                    ? 'deep-orange lighten-4'
                    : ''
                "
                :class="
                  valorTotalSendoPago > valorEmAberto && valorTotalSendoPago > 0
                    ? 'animate__animated animate__flash animate__delay-2s'
                    : ''
                "
              />
              <v-currency-field
                v-model="form.multa"
                dense
                class="mx-1"
                outlined
                label="Multa"
                :background-color="
                  valorTotalSendoPago > valorEmAberto && valorTotalSendoPago > 0
                    ? 'deep-orange lighten-4'
                    : ''
                "
                :class="
                  valorTotalSendoPago > valorEmAberto && valorTotalSendoPago > 0
                    ? 'animate__animated animate__flash animate__delay-2s'
                    : ''
                "
              />
              <v-currency-field
                v-model="form.desconto"
                dense
                outlined
                class="ml-1"
                :background-color="
                  valorTotalSendoPago < valorEmAberto && valorTotalSendoPago > 0
                    ? 'deep-orange lighten-4'
                    : ''
                "
                :class="
                  valorTotalSendoPago < valorEmAberto && valorTotalSendoPago > 0
                    ? 'animate__animated animate__flash animate__delay-2s'
                    : ''
                "
                label="Desconto"
              />
            </div>
            <div
              v-for="mensalidade of form.mensalidades"
              :key="form.mensalidades.indexOf(mensalidade)"
              class="d-flex"
            >
              <v-select
                v-model="mensalidade.forma_de_pagamento_id"
                :items="formasDePagamento"
                :loading="carregandoFormasDePagamento"
                item-text="descricao"
                item-value="id"
                label="Forma de Pagamento"
                dense
                class="mr-1"
                outlined
              />
              <v-currency-field
                v-model="mensalidade.valor"
                outlined
                class="ml-1"
                dense
                label="Valor sendo pago"
              />
              <v-btn
                v-if="form.mensalidades.indexOf(mensalidade) === form.mensalidades.length - 1"
                color="success"
                class="ml-2"
                :disabled="baixandoParcela"
                @click="adicionarMensalidade"
              >
                <v-icon> fa fa-plus </v-icon>
              </v-btn>
              <v-btn
                v-else
                color="error"
                class="ml-2"
                @click="() => deletarMensalidade(mensalidade)"
              >
                <v-icon> fa fa-close </v-icon>
              </v-btn>
            </div>
            <v-btn
              color="secondary"
              :disabled="
                baixandoParcela || valorTotalSendoPago <= 0 || valorTotalSendoPago > valorEmAberto
              "
              :loading="baixandoParcela"
              block
              @click="baixarParcela"
            >
              Baixar Parcela
            </v-btn>
          </form>
          <div v-if="parcelaSelecionada && valorTotalSendoPago > 0" class="mt-3">
            <v-alert
              v-if="situacaoDaParcelaAposBaixar == 0"
              class="animate__animated animate__pulse"
              border="bottom"
              type="warning"
            >
              <template v-slot:prepend="">
                <v-icon class="mx-3" x-large> fa fa-exclamation </v-icon>
              </template>
              <div class="ml-3">
                O valor sendo pago está abaixo do valor em aberto, se essa parcela for baixada da
                forma como está, ela continuará EM ABERTO. caso a intenção seja dar um desconto,
                informe o valor do desconto no campo "desconto"
              </div>
            </v-alert>
            <v-alert
              v-else-if="valorTotalSendoPago > valorEmAberto"
              type="error"
              border="bottom"
              class="animate__animated animate__headShake"
            >
              <template v-slot:prepend="">
                <v-icon x-large> fa fa-warning </v-icon>
              </template>
              <div class="ml-3">
                O valor está acima do valor real da parcela, caso queira colocar juros/multa sobre a
                parcela, informe o valor dos juros/multa nos devidos campos.
              </div>
            </v-alert>
            <v-alert
              v-else-if="situacaoDaParcelaAposBaixar == 1"
              class="animate__animated animate__pulse"
              border="bottom"
              type="success"
            >
              <template v-slot:prepend="">
                <v-icon x-large> fa fa-check </v-icon>
              </template>
              <div class="ml-3">Tudo OK, A Parcela ficará como paga!</div>
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { $toast } from "vuetify-snackbar-toast";
import store from "@/store";

export default {
  data() {
    return {
      form: null,
      baixandoParcela: false,
    };
  },
  computed: {
    ...mapGetters("Mensalidades", ["matriculaSelecionada", "parcelaSelecionada"]),
    ...mapGetters("FormasDePagamento", ["formasDePagamento", "carregandoFormasDePagamento"]),
    situacaoDaParcelaAposBaixar() {
      if (!this.valorEmAberto || !this.valorTotalSendoPago) return null;
      if (this.valorTotalSendoPago >= this.valorEmAberto) {
        return 1;
      }
      return 0;
    },
    valorEmAberto() {
      if (!this.parcelaSelecionada || !this.form) return null;
      const valorEmAberto =
        this.parcelaSelecionada.valor +
        this.form.juros +
        this.form.multa -
        this.parcelaSelecionada.valorpago -
        this.form.desconto;
      return parseFloat(valorEmAberto.toFixed(2));
    },
    valorTotalSendoPago() {
      if (!this.parcelaSelecionada) return null;
      const valorTotalSendoPago = this.form.mensalidades.reduce(
        (acc, mensalidade) => acc + mensalidade.valor,
        0
      );
      return parseFloat(valorTotalSendoPago.toFixed(2));
    },
  },
  mounted() {
    this.$store.dispatch("FormasDePagamento/syncFormasDePagamento");
    this.form = {
      juros: this.parcelaSelecionada.juros,
      multa: this.parcelaSelecionada.juros,
      desconto: this.parcelaSelecionada.juros,
      mensalidades: [
        {
          valor: 0,
          forma_de_pagamento_id: 1,
        },
      ],
    };
  },
  methods: {
    async baixarParcela() {
      this.baixandoParcela = true;
      try {
        const parcelaAtualizada = await this.$services.parcelasService.baixarParcela(
          this.parcelaSelecionada,
          {
            ...this.form,
          }
        );
        this.$store.commit("Mensalidades/setParcelaSelecionada", parcelaAtualizada);
        this.atualizarMatriculaSelecionada(parcelaAtualizada);
        this.$toast.success("Baixa feita com sucesso!");
        this.$router.push({ name: "mensalidades" });
      } catch (error) {
        this.$handleError(error);
      }
      this.baixandoParcela = false;
    },
    atualizarMatriculaSelecionada(parcelaAtualizada) {
      const matriculaSelecionadaAtualizada = {
        ...this.matriculaSelecionada,
        parcelas: this.matriculaSelecionada.parcelas.map((parcela) =>
          parcela.id !== parcelaAtualizada.id ? parcela : parcelaAtualizada
        ),
      };
      this.$store.commit("Mensalidades/setMatriculaSelecionada", matriculaSelecionadaAtualizada);
    },
    goBack() {
      if (!this.$router.back()) this.$router.push({ name: "mensalidades" });
    },
    adicionarMensalidade() {
      if (!this.form || !this.form.mensalidades) return;
      this.form.mensalidades.push({
        forma_de_pagamento_id: 1,
        valor: 0,
      });
    },
    deletarMensalidade(mensalidade) {
      if (!this.form || !this.form.mensalidades) return;
      this.form.mensalidades = this.form.mensalidades.filter(
        (currentMensalidade) => currentMensalidade !== mensalidade
      );
    },
    async carregarFormasDePagamento() {
      try {
        await this.$services.formasDePagamentoService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    const parcelaSelecionada = store.getters["Mensalidades/parcelaSelecionada"];
    const matriculaSelecionada = store.getters["Mensalidades/matriculaSelecionada"];
    if (!parcelaSelecionada || !matriculaSelecionada) {
      $toast.warn("Nenhuma matricula/parcela selecionada");
      next({ name: "mensalidades" });
    }
    next();
  },
};
</script>
