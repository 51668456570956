import MapCollection from "collections/map";
import Vue from "vue";
import rolesService from "@/Services/RolesService";
import $handleError from "@/plugins/handleError";
import { $constants } from "@/plugins/constants";

const state = () => ({
  roles: [],
  carregandoRoles: false,
  rolesLoaded: false,
  rolesList: [],
  outrosRolesList: [],
  rolesParents: [],
});
const mutations = {
  setRoles(state, roles) {
    const rolesMap = new MapCollection();
    roles.map((role) => rolesMap.set(role.id, role));
    Vue.set(state, "roles", rolesMap);
    Vue.set(state, "rolesLoaded", true);
    Vue.set(state, "rolesList", Array.from(state.roles.values()));
    const outrosRolesList = state.rolesList.filter(
      (role) =>
        !$constants.rolesParaProfessores.map((role) => role.id).includes(role.id) &&
        role.name !== "aluno"
    );
    Vue.set(state, "outrosRolesList", outrosRolesList);
  },
  setRolesParents(state, roles) {
    const rolesMap = new MapCollection();
    roles.map((role) => rolesMap.set(role.id, role));
    Vue.set(state, "roles", rolesMap);
    Vue.set(state, "rolesLoaded", true);
    Vue.set(state, "rolesList", Array.from(state.roles.values()));
    const rolesParents = state.rolesList.filter(
      (role) =>
        !$constants.rolesParaGestores.map((role) => role.id).includes(role.id) &&
        role.name !== "aluno"
    );
    Vue.set(state, "rolesParents", rolesParents);
  },
  setCarregandoRoles(state, newValue) {
    state.carregandoRoles = newValue;
  },
  adicionarRole(state, role) {
    state.roles.set(role.id, role);
    Vue.set(state, "rolesList", Array.from(state.roles.values()));
    Vue.set(state, "roles", state.roles);
  },
  atualizarRole(state, role) {
    state.roles.set(role.id, role);
    Vue.set(state, "rolesList", Array.from(state.roles.values()));
    Vue.set(state, "roles", state.roles);
  },
  deletarRole(state, roleId) {
    state.roles.delete(roleId);
    Vue.set(state, "rolesList", Array.from(state.roles.values()));
    Vue.set(state, "roles", state.roles);
  },
};
const actions = {
  async loadRoles({ commit }, options = {}) {
    commit("setCarregandoRoles", true);
    try {
      const rolesList = await rolesService.syncAll(options.force);
      commit("setRoles", rolesList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoRoles", false);
  },
  async loadRolesParents({ commit }, options = {}) {
    commit("setCarregandoRoles", true);
    try {
      const rolesList = await rolesService.syncAll(options.force);
      commit("setRolesParents", rolesList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoRoles", false);
  },
};
const getters = {
  roles: (state) => state.roles,
  carregandoRoles: (state) => state.carregandoRoles,
  rolesLoaded: (state) => state.rolesLoaded,
  rolesList: (state) => state.rolesList,
  outrosRolesList: (state) => state.outrosRolesList,
  rolesParents: (state) => state.rolesParents,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
