import Axios from "@/plugins/Axios";
import store from "@/store";
import Curso from "@/Models/Curso";

export class TiposDeEnsinosService {
  async syncAll() {
    // const cursosList = store.getters["TiposDeEnsinos/cursos"];
    // if (force || cursosList.length === 0) {
    const response = await Axios().get("cursos");
    const cursos = response.data.map((tipoDeEnsino) => new Curso(tipoDeEnsino));
    return cursos;
    // }
    // return cursosList;
  }

  async criarTipoDeEnsino(tipoDeEnsino) {
    const response = await Axios().post("cursos", tipoDeEnsino);
    const novaCurso = response.data;
    store.commit("TiposDeEnsinos/adicionarTipoDeEnsino", novaCurso);
    return novaCurso;
  }

  async atualizarTipoDeEnsino(tipoDeEnsino) {
    const response = await Axios().put(`cursos/${tipoDeEnsino.id}`, tipoDeEnsino);
    const cursoAtualizada = response.data;
    store.commit("TiposDeEnsinos/atualizarTipoDeEnsino", cursoAtualizada);
    return cursoAtualizada;
  }

  async deletarTipoDeEnsino(tipoDeEnsino) {
    const response = await Axios().delete(`cursos/${tipoDeEnsino.id}`);
    const cursoDeletada = response.data;
    store.commit("TiposDeEnsinos/deletarTipoDeEnsino", tipoDeEnsino.id);
    return cursoDeletada;
  }

  async recuperarTipoDeEnsino(tipoDeEnsino) {
    return this.atualizarCurso({ ...tipoDeEnsino, deleted_at: null });
  }
}
export default new TiposDeEnsinosService();
