<template>
  <div>
    <v-alert
      class="clickable"
      border="left"
      elevation="3"
      type="warning"
      :loading="verificandoForcaDaSenha"
      @click="() => (open = true)"
      >Sua senha está fraca, altere sua senha clicando aqui</v-alert
    >
    <e-change-password-modal v-model="open" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["verificandoForcaDaSenha"]),
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
