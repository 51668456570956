class Modals {
  static instance;

  static callbackFunction;

  static setOptions = () => {};

  static closeFunction = () => {};

  static getInstance() {
    if (!Modals.instance) {
      Modals.instance = new Modals();
    }
    return Modals.instance;
  }

  setCallbackFunction(fn) {
    Modals.callbackFunction = fn;
  }

  setCloseFunction(fn) {
    Modals.closeFunction = fn;
  }

  setSetOptionsFunction(fn) {
    Modals.setOptions = fn;
  }

  setOptions(options) {
    Modals.setOptions(options);
  }

  openModal(options) {
    if (!Modals.callbackFunction) return;
    Modals.callbackFunction(options);
  }

  warn(options) {
    this.openModal({ ...options, iconName: "fa fa-exclamation-circle", iconColor: "warning" });
  }

  info(options) {
    this.openModal({ ...options, iconName: "fa fa-info-circle", iconColor: "info" });
  }

  danger(options) {
    this.openModal({ ...options, iconName: "fa fa-warning", iconColor: "error" });
  }

  close() {
    Modals.closeFunction();
  }
}

export const $modals = Modals.getInstance();

export default (vue) => {
  vue.prototype.$modals = $modals;
};
