<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Usuários </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarUsuario" class="mr-2">Criar Usuário</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="outrosUsuariosList"
          :loading="carregandoUsuarios"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="usuario.estaDeletado ? ['deleted'] : []"
                v-for="usuario of items"
                :key="usuario.id"
              >
                <td>{{ usuario.id }}</td>
                <!-- <td>{{ usuario.codigo }}</td> -->
                <td>{{ usuario.name }}</td>
                <td>{{ usuario.email }}</td>
                <td><e-user-role :value="usuario" /></td>
                <td>
                  <v-tooltip v-if="!usuario.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarUsuario(usuario)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar usuario</span>
                  </v-tooltip>
                  <v-tooltip v-if="!usuario.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarUsuario(usuario)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar usuario</span>
                  </v-tooltip>
                  <v-tooltip v-if="usuario.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarUsuario(usuario)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar usuario</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="carregarUsuarios"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Usuario from "../../Models/User";

export default {
  computed: {},
  data() {
    return {
      usuarios: [],
      carregandoUsuarios: false,
      usuariosLoaded: false,
      usuariosList: [],
      outrosUsuariosList: [],

      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Nome", value: "name" },
          { text: "Login de acesso", value: "email" },
          { text: "Perfil", value: "role_id" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },

  mounted() {
    this.carregandoUsuarios = true;
    this.$loaderService.open("Carregando Usuarios");
    this.carregarUsuarios(this.pageNumber);
    this.carregandoUsuarios = false;
    this.$loaderService.close();
  },

  watch: {
    carregandoUsuarios(value) {
      if (value && this.outrosUsuariosList.length === 0) {
        this.$loaderService.open("Carregando usuarios");
      } else {
        this.$loaderService.close();
      }
    },

    async search(val) {
      if (val.length > 1) {
        await this.carregarUsuarios(0, val);
      }
      if (!val) {
        await this.carregarUsuarios(0);
      }
    },
  },
  methods: {
    async carregarUsuarios(pageNumber, query = null) {
      try {
        const response = await this.$services.usuariosService.syncAllPaginate(pageNumber, query);
        this.paginate = response.data;
        const usuarios = response.data.data.map((usuario) => new Usuario(usuario));
        this.outrosUsuariosList = usuarios;
      } catch (error) {
        this.$handleError(error);
      }
    },
    criarUsuario() {
      this.$router.push({ name: "usuarios.create" });
    },
    editarUsuario(usuario) {
      this.$router.push({ name: "usuarios.edit", params: { usuario_id: usuario.id } });
      // return usuario;
    },
    async deletarUsuario(usuario) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta usuario?",
        confirmationCode: usuario.id,
        confirmationMessage: `Por favor, digite <strong>${usuario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Usuario");
              try {
                await this.$services.usuariosService.deletarUsuario(usuario);
                this.$toast.success("Usuario deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarUsuario(usuario) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta usuario?",
        confirmationCode: usuario.id,
        confirmationMessage: `Por favor, digite <strong>${usuario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Usuario");
              try {
                await this.$services.usuariosService.recuperarUsuario(usuario);
                this.$toast.success("Usuario recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
