import MapCollection from "collections/map";
import Vue from "vue";
import usuariosService from "@/Services/UsuariosService";
import $handleError from "@/plugins/handleError";
import { $constants } from "@/plugins/constants";

const state = () => ({
  usuarios: [],
  carregandoUsuarios: false,
  usuariosLoaded: false,
  usuariosList: [],
  outrosUsuariosList: [],
});
function setUsuarios(state, usuarios) {
  const usuariosMap = new MapCollection();
  usuarios.map((usuario) => usuariosMap.set(usuario.id, usuario));
  Vue.set(state, "usuarios", usuariosMap);
  Vue.set(state, "usuariosLoaded", true);
  Vue.set(state, "usuariosList", Array.from(state.usuarios.values()));
  const outrosUsuariosList = state.usuariosList.filter(
    (usuario) =>
      usuario.role &&
      !$constants.rolesParaProfessores.map((role) => role.id).includes(usuario.role.id) &&
      usuario.role.name !== "aluno"
  );
  Vue.set(state, "outrosUsuariosList", outrosUsuariosList);
}
const mutations = {
  setUsuarios,
  setCarregandoUsuarios(state, newValue) {
    state.carregandoUsuarios = newValue;
  },
  adicionarUsuario(state, usuario) {
    state.usuarios.set(usuario.id, usuario);
    Vue.set(state, "usuariosList", Array.from(state.usuarios.values()));
    Vue.set(state, "usuarios", state.usuarios);
  },
  atualizarUsuario(state, usuario) {
    state.usuarios.set(usuario.id, usuario);
    Vue.set(state, "usuariosList", Array.from(state.usuarios.values()));
    Vue.set(state, "usuarios", state.usuarios);
  },
  deletarUsuario(state, usuarioId) {
    state.usuarios.delete(usuarioId);
    setUsuarios(state, Array.from(state.usuarios.values()));
    // Vue.set(state, "usuariosList", Array.from(state.usuarios.values()));
    // Vue.set(state, "usuarios", state.usuarios);
  },
};
const actions = {
  async loadUsuarios({ commit }, options = {}) {
    commit("setCarregandoUsuarios", true);
    try {
      const usuariosList = await usuariosService.syncAll(options.force);
      commit("setUsuarios", usuariosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoUsuarios", false);
  },
};
const getters = {
  usuarios: (state) => state.usuarios,
  carregandoUsuarios: (state) => state.carregandoUsuarios,
  usuariosLoaded: (state) => state.usuariosLoaded,
  usuariosList: (state) => state.usuariosList,
  outrosUsuariosList: (state) => state.outrosUsuariosList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
