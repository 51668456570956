<template>
  <div>
    <v-pagination v-model="localCurrent_page" :length="last_page" :total-visible="8"></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    current_page: {
      type: Number,
      required: true,
    },
    last_page: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  watch: {
    localCurrent_page(newVal) {
      this.$emit("changeActionResponse", newVal, this.search);
    },
  },
  data() {
    return {
      localCurrent_page: this.current_page,
    };
  },
};
</script>
