import Axios from "@/plugins/Axios";

export class ImageServices {
  async uploadImage(url, file, id) {
    const formData = new FormData();
    formData.append("file", file);
    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });
    const response = await Axios().post(`${url}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  async downloadImage(url) {
    const base64 = await Axios()
      .get(url, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:image/jpeg;base64, ${base64}`;
  }
}
export default new ImageServices();
