import MapCollection from "collections/map";
import tiposDeEnsinosService from "@/Services/TiposDeEnsinosService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  tiposDeEnsinos: [],
  carregandoTiposDeEnsinos: false,
  tiposDeEnsinosLoaded: false,
  tiposDeEnsinosList: [],
});
const mutations = {
  setTiposDeEnsinos(state, tiposDeEnsinos) {
    const tiposDeEnsinosMap = new MapCollection();
    tiposDeEnsinos.map((tipoDeEnsino) => tiposDeEnsinosMap.set(tipoDeEnsino.id, tipoDeEnsino));
    state.tiposDeEnsinos = tiposDeEnsinosMap;
    state.tiposDeEnsinosLoaded = true;
    // state = {...state, tiposDeEnsinos: tiposDeEnsinosMap, tiposDeEnsinosLoaded: true}
  },
  setCarregandoTiposDeEnsinos(state, newValue) {
    state.carregandoTiposDeEnsinos = newValue;
  },
  adicionarTipoDeEnsino(state, tipoDeEnsino) {
    state.tiposDeEnsinos.set(tipoDeEnsino.id, tipoDeEnsino);
  },
  atualizarTipoDeEnsino(state, tipoDeEnsino) {
    state.tiposDeEnsinos.set(tipoDeEnsino.id, tipoDeEnsino);
  },
  deletarTipoDeEnsino(state, tipoDeEnsinoId) {
    state.tiposDeEnsinos.delete(tipoDeEnsinoId);
  },
};
const actions = {
  async loadTiposDeEnsinos({ commit }, options = {}) {
    commit("setCarregandoTiposDeEnsinos", true);
    try {
      const tiposDeEnsinosList = await tiposDeEnsinosService.syncAll(options.force);
      commit("setTiposDeEnsinos", tiposDeEnsinosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoTiposDeEnsinos", false);
  },
};
const getters = {
  tiposDeEnsinos: (state) => state.tiposDeEnsinos,
  carregandoTiposDeEnsinos: (state) => state.carregandoTiposDeEnsinos,
  tiposDeEnsinosLoaded: (state) => state.tiposDeEnsinosLoaded,
  tiposDeEnsinosList: (state) => Array.from(state.tiposDeEnsinos.values()),
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
