<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Métricas da turma
        </h1>
      </v-col>
    </v-row>
    <v-card class="mx-auto my-3">
      <v-row>
        <v-col cols="12">
          <v-card-title>Gestão</v-card-title>
          <v-card-text>
            <v-row align="center" class="mx-0">
              <div class="grey--text">
                {{ gestao.turma.curso ? gestao.turma.curso.descricao : "- - -" }}
              </div>
            </v-row>
            <v-row align="center" class="mx-0">
              <div class="grey--text">
                {{ gestao.turma.descricao ? gestao.turma.descricao : "- - - " }}
              </div>
            </v-row>
            <v-row align="center" class="mx-0">
              <div class="grey--text">
                {{ gestao.disciplina.descricao ? gestao.disciplina.descricao : "- - -" }}
              </div>
            </v-row>
            <div class="my-4 text-subtitle-1">
              {{ gestao.professor.nome ? gestao.professor.nome : "- - -" }}
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>

          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-card-title>Total de aulas</v-card-title>
              <v-card-text>
                <v-chip>{{ gestao.aulas.length }}</v-chip>
              </v-card-text>
            </v-col>

            <v-col cols="12" xs="12" sm="6">
              <v-card-title>Avaliação da turma</v-card-title>
              <v-card-text>
                <v-card-actions>
                  <span class="grey--text"> ({{ mediaDaTurma }}) </span>
                  <v-rating
                    :value="(mediaDaTurma / 2).toFixed(1)"
                    length="5"
                    background-color="primary"
                    color="primary accent-4"
                    dense
                    readonly
                    size="32"
                  ></v-rating>

                  <v-spacer></v-spacer>
                  <span class="grey--text mr-2">
                    {{ emotions[Math.ceil(mediaDaTurma / 2)] }}
                  </span>
                </v-card-actions>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-divider></v-divider>

    <v-row class="mt-3 mb-3">
      <v-col>
        <v-chip color="indigo" label text-color="white"
          >Quantidade de notas acima e abaixo da média
        </v-chip>
        <v-card class="mt-2">
          <e-dashboard-turma :datasets="datasetsNotas" :labels="labelsNotas" />
        </v-card>
      </v-col>

      <v-col>
        <v-chip color="indigo" label text-color="white"> Média de notas </v-chip>
        <v-card class="mt-2">
          <e-dashboard-turma :datasets="datasetsMedias" :labels="labelsMedias" />
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-3 mb-3">
      <v-col>
        <v-chip color="indigo" label text-color="white"
          >Quantidade de presenças e faltas por mês</v-chip
        >
        <v-card class="mt-2">
          <e-dashboard-turma :datasets="datasetsAcompanhamentos" :labels="labelsAcompanhamentos" />
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-3 mb-1">
      <v-col cols="12">
        <v-chip color="indigo" label text-color="white"> Melhores alunos</v-chip>
        <v-card v-for="(item, i) in melhoresAlunos" :key="i" light class="mb-3 mt-1">
          <div class="d-flex flex-no-wrap justify-content">
            <div style="border-right: 1px solid #ccc" class="py-5">
              <div class="d-flex flex-no-wrap justify-content">
                <v-img width="100" :src="coroas[i]"></v-img>
              </div>
              <div style="text-align: center">
                <h3>#{{ ++i }}</h3>
              </div>
            </div>

            <div class="py-5 ml-2">
              <div>
                <h3 class="indigo--text mb-3">
                  {{ item.aluno }}
                </h3>
              </div>
              <v-divider></v-divider>
              <div class="mt-3">
                <span class="">Média: {{ item.media }}</span> <br />
                <span class="">Notas: {{ item.soma_notas }}</span> <br />
                <span class="">Faltas: {{ item.qtdFaltas }}</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-2">
      <v-col>
        <v-chip color="indigo" label text-color="white"> Alunos </v-chip>
        <v-card class="mt-2">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="alunos" :search="search"></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      turma: {},
      datasetsNotas: [],
      labelsNotas: [],
      datasetsMedias: [],
      labelsMedias: [],
      datasetsAcompanhamentos: [],
      labelsAcompanhamentos: [],
      turmaId: null,
      gestaoId: null,
      gestao: {},
      alunos: [],
      melhoresAlunos: [],
      mediaDaTurma: null,
      coroas: [
        "https://img.freepik.com/vetores-gratis/composicao-da-coroa-real-com-imagem-plana-da-coroa-cercada-por-coroas-de-louro-douradas_1284-64252.jpg?w=740&t=st=1661362754~exp=1661363354~hmac=29523ea348e1807edaa9ca4d5abf172c4753f0e7d8a6a968a3c2ff064eaa087a",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
      ],
      search: "",
      headers: [
        { text: "Nome", value: "aluno" },
        { text: "Média", value: "media" },
        { text: "Notas", value: "soma_notas" },
        { text: "Faltas", value: "qtdFaltas" },
        { text: "Presenças", value: "qtdPresencas" },
      ],
      emotions: ["Péssimo(a)", "Ruim", "Mediano(a)", "Bom", "Excelente", "Excelente"],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getTurmaId() {
      const { id } = this.$route.params;
      this.turmaId = id;
    },
    getGestaoId() {
      const { gestao } = this.$route.params;
      this.gestaoId = gestao;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.getTurmaId();
        this.getGestaoId();
        const data = await this.$services.turmasService.mostrarTurma(this.turmaId);
        const gestao = await this.$services.gestoesDeAulasService.visualizar(this.gestaoId);
        this.turma = { ...data.data.turma };
        this.gestao = { ...gestao };
        this.getNotas();
        this.getMedias();
        this.getAcompanhamento();
        this.getAlunos();
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getNotas() {
      try {
        const data = await this.$services.turmasService.pegarNotas({ turmas: [this.turmaId] });
        const values = data.data;
        const dados = [];
        Object.keys(values).forEach((key) => {
          dados.push({
            label: values[key].label,
            backgroundColor: values[key].backgroundColor,
            data: values[key].data.map((n) => parseInt(n, 10)).filter((n) => n),
          });
        });
        this.datasetsNotas = dados;
        this.labelsNotas = values.notasAzuis.texts;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getMedias() {
      try {
        const data = await this.$services.turmasService.pegarMedias({ turmas: [this.turmaId] });
        const values = data.data;
        const dados = [];
        Object.keys(values).forEach((key) => {
          dados.push({
            label: values[key].label,
            backgroundColor: values[key].backgroundColor,
            data: values[key].data.map((n) => parseInt(n, 10)).filter((n) => n),
          });
        });
        const notas = dados[0].data.map((n) => parseInt(n, 10)).filter((n) => n);
        this.mediaDaTurma = notas[notas.length - 1];
        this.labelsMedias = values.notas.texts;
        this.datasetsMedias = dados;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getAcompanhamento() {
      try {
        const data = await this.$services.turmasService.pegarAcompanhamentos({
          turmas: [this.turmaId],
        });
        const values = data.data;
        const dados = [];
        Object.keys(values).forEach((key) => {
          dados.push({
            label: values[key].label,
            backgroundColor: values[key].backgroundColor,
            data: values[key].data.map((n) => parseInt(n, 10)),
          });
        });
        this.datasetsAcompanhamentos = dados;
        this.labelsAcompanhamentos = values.faltas.mesesAll.map((m) => m.descricao);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getAlunos() {
      try {
        const data = await this.$services.turmasService.pegarMelhoresAlunos({
          turmas: [this.turmaId],
        });
        this.alunos = data.data;
        this.melhoresAlunos = data.data
          .map((value, index) => (index < 3 ? value : null))
          .filter((a) => a);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
