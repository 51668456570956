import Vue from "vue";
import User from "@/Models/User";
import authService from "@/Services/AuthService";

const state = () => ({
  user: null,
  senhaFraca: false,
  verificandoForcaDaSenha: false,
});
const mutations = {
  setUser(state, user) {
    if (!user) {
      state.user = null;
      return;
    }
    state.user = new User(user);
  },
  setSenhaFraca(state, senhaFraca) {
    Vue.set(state, "senhaFraca", senhaFraca);
  },
  setVerificandoForcaDaSenha(state, verificandoForcaDaSenha) {
    Vue.set(state, "verificandoForcaDaSenha", verificandoForcaDaSenha);
  },
};
const actions = {
  async verificarForcaDaSenha({ commit }) {
    commit("setVerificandoForcaDaSenha", true);
    try {
      await authService.verificarForcaDaSenha();
      commit("setSenhaFraca", false);
    } catch (error) {
      commit("setSenhaFraca", true);
    }
    commit("setVerificandoForcaDaSenha", false);
  },
};
const getters = {
  user: (state) => state.user,
  senhaFraca: (state) => state.senhaFraca,
  verificandoForcaDaSenha: (state) => state.verificandoForcaDaSenha,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
