<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">INEP </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-medium">
              Importação
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                  <v-row>
                    <v-col cols="8">
                      <v-checkbox
                        v-for="opcao of opcoes"
                        v-model="form.tiposDeLinhas"
                        :label="`${opcao.value} - ${opcao.name}`"
                        :value="opcao.value"
                        :key="opcao.value"
                        multiple
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <ValidationProvider name="Arquivo" rules="required" v-slot="{ errors }">
                        <v-file-input
                          label="Arquivo em anexo"
                          v-model="form.file"
                          outlined
                          dense
                          counter
                          show-size
                          :error-messages="errors"
                        ></v-file-input>
                      </ValidationProvider>
                      <v-btn color="secondary" type="submit" block>Enviar</v-btn>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-medium">
              Exportação
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      submittingForm: false,
      form: {
        tiposDeLinhas: [],
        file: null,
      },
      opcoes: [
        {
          name: "Sequência dos registros de identificação da escola",
          value: "00",
        },
        {
          name: "Caracterização e infraestrutura da escola",
          value: "10",
        },
        {
          name: "Turmas",
          value: "20",
        },
        {
          name: "Pessoas físicas",
          value: "30",
        },
        {
          name: "Vínculos de gestores escolares",
          value: "40",
        },
        {
          name: "Vínculos de profissionais escolares",
          value: "50",
        },
        {
          name: "Vínculos de alunos",
          value: "60",
        },
      ],
    };
  },
  methods: {
    async submitForm() {
      try {
        this.submittingForm = true;
        const formData = new FormData();
        formData.append("file", this.form.file);
        formData.append("tiposDeLinhas", this.form.tiposDeLinhas);

        await this.$services.inepService.importar(formData);
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
