import Agenda from "@/Pages/Agenda/index.vue";
import Aluno from "@/Pages/Agenda/aluno.vue";
import NewEditAgenda from "@/Pages/Agenda/newEditAgenda.vue";

export default [
  {
    path: "/agenda",
    name: "agenda.index",
    component: Agenda,
  },
  {
    path: "/agenda/create",
    name: "agenda.create",
    component: NewEditAgenda,
  },
  {
    path: "/agenda/edit/:agenda_id",
    name: "agenda.edit",
    component: NewEditAgenda,
    props: { editing: true },
  },
  {
    path: "/agenda/aluno",
    name: "agenda.aluno",
    component: Aluno,
    props: { editing: true },
  },
];
