function normalize(str) {
  return (str || "")
    .toString()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

function searchQuery(item, queryText, itemText) {
  const subStrList = normalize(queryText).split(" ");
  const subStrIncludedList = subStrList.filter((subStr) => normalize(itemText).includes(subStr));
  return subStrIncludedList.length >= subStrList.length;
  // return false;
  // return normalize(itemText).includes(normalize(queryText));
}

export function dataTableFilter(itemText, queryText) {
  return searchQuery(null, queryText, itemText);
}

export default searchQuery;
