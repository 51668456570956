<template>
  <div>
    <v-card class="mx-auto col-lg-12 mt-5" v-if="inscricao_situacao === false">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="4" class="my-4" xs="12" justify="center" align="center">
          <v-img max-width="250" src="/img/i-congresso-educacao.png"></v-img>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row>
          <v-col align="center">
            <v-card
              color="#fbbb14
"
              @click="
                {
                }
              "
              style="height: 100%"
              class="col-md-7"
            >
              <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="1000">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <span class="title font-weight-light">
                      II INTEGRA SEDUC<br />
                      I CONGRESSO LUISCORREIENSE DE<br />
                      EDUCAÇÃO<br />
                    </span>
                    <span class="title font-weight-light text-overline">
                      28 E 29 DE OUTUBRO<br />
                    </span>
                    <span class="title font-weight-black text-h4" align-text="center">
                      FAÇA AQUI SUA INSCRIÇÃO
                    </span>
                  </div>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-col align="center">
                      <div class="title font-weight-black text-h4 mt-5">
                        Inscreva-se clicando nos dias abaixo
                      </div>
                    </v-col>
                    <v-card-text>
                      <div class="text-h2">
                        <v-row>
                          <v-col align="center" v-if="inscritos1 <= 130">
                            <v-card
                              color="#fbbb14"
                              @click="() => openDialog(1)"
                              style="height: 100%"
                            >
                              <div class="title font-weight-black my-5 pt-5">
                                1º DIA – 28/10- MANHÃ<br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                8:00h - CREDENCIAMENTO<br />
                                9:00h - SOLENIDADE DE ABERTURA<br />
                                10:00h – Palestra Magna: APRENDIZAGEM ATIVA E COLABORATIVA, PARA
                                ALÉM DO CONTEÚDO – Ricardo Ramos Fragelli.<br />
                              </div>
                              <div
                                class="title font-weight-light font-italic text-h5 my-6 px-6"
                                align-text="center"
                              >
                                CAPACIDADE MÁXIMA DE 130 PARTICIPANTES
                              </div>
                            </v-card>
                          </v-col>
                          <v-col align="center" v-else>
                            <v-card color="grey" style="height: 100%">
                              <div class="title font-weight-black my-5 pt-5">
                                1º DIA – 28/10- MANHÃ<br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                8:00h - CREDENCIAMENTO<br />
                                9:00h - SOLENIDADE DE ABERTURA<br />
                                10:00h – Palestra Magna: APRENDIZAGEM ATIVA E COLABORATIVA, PARA
                                ALÉM DO CONTEÚDO – Ricardo Ramos Fragelli.<br />
                              </div>
                              <div
                                class="title font-weight-light font-italic text-h5 my-6 px-6"
                                align-text="center"
                              >
                                VAGAS ESGOTADAS
                              </div>
                            </v-card>
                          </v-col>

                          <v-col align="center" v-if="inscritos2 <= 130">
                            <v-card
                              color="#fbbb14"
                              @click="() => openDialog(2)"
                              style="height: 100%"
                            >
                              <div class="title font-weight-black my-5 pt-5">
                                1º DIA – 28/10- TARDE<br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                14:00h – CREDENCIAMENTO<br />
                                15:00h – Palestra 2: SAÚDE MENTAL DO PROFESSOR E DO ALUNO - Thaís
                                Faria Coelho. <br />
                                16:00h – Palestra 3: O acolhimento de alunos e professores e o papel
                                da família no retorno às aulas presenciais – Geraldo Peçanha.<br />
                              </div>
                              <div
                                class="title font-weight-light font-italic text-h5 my-6 px-6"
                                align-text="center"
                              >
                                CAPACIDADE MÁXIMA DE 130 PARTICIPANTES
                              </div>
                            </v-card>
                          </v-col>
                          <v-col align="center" v-else>
                            <v-card color="grey" @click="() => openDialog(2)" style="height: 100%">
                              <div class="title font-weight-black my-5 pt-5">
                                1º DIA – 28/10- TARDE<br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                14:00h – CREDENCIAMENTO<br />
                                15:00h – Palestra 2: SAÚDE MENTAL DO PROFESSOR E DO ALUNO - Thaís
                                Faria Coelho. <br />
                                16:00h – Palestra 3: O acolhimento de alunos e professores e o papel
                                da família no retorno às aulas presenciais – Geraldo Peçanha.<br />
                              </div>
                              <div
                                class="title font-weight-light font-italic text-h5 my-6 px-6"
                                align-text="center"
                              >
                                VAGAS ESGOTADAS
                              </div>
                            </v-card>
                          </v-col>

                          <v-col align="center" v-if="inscritos3 <= 130">
                            <v-card
                              color="#fbbb14"
                              @click="() => openDialog(3)"
                              style="height: 100%"
                            >
                              <div class="title font-weight-black my-5 pt-5">
                                2º DIA – 29/10 - TARDE<br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                14:00h - CREDENCIAMENTO<br />
                                15:00h – A IMPORTÂNCIA DA NOVA EDUCAÇÃO – Jáder Montenegro.<br />
                                16:00h – Palestra de Encerramento - A ESCOLA DO PRESENTE: motivação
                                e novas competências para o ensino híbrido e presencial – Max
                                Haetinger.<br />
                                18:00h – Live de encerramento – Show Cultural. <br />
                              </div>
                              <div
                                class="title font-weight-light font-italic text-h5 my-6 px-6"
                                align-text="center"
                              >
                                CAPACIDADE MÁXIMA DE 130 PARTICIPANTES
                              </div>
                            </v-card>
                          </v-col>
                          <v-col align="center" v-else>
                            <v-card color="grey" style="height: 100%">
                              <div class="title font-weight-black my-5 pt-5">
                                2º DIA – 28/10- TARDE<br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                14:00h - CREDENCIAMENTO<br />
                                15:00h – A IMPORTÂNCIA DA NOVA EDUCAÇÃO – Jáder Montenegro.<br />
                                16:00h – Palestra de Encerramento - A ESCOLA DO PRESENTE: motivação
                                e novas competências para o ensino híbrido e presencial – Max
                                Haetinger.<br />
                                18:00h – Live de encerramento – Show Cultural. <br />
                              </div>
                              <div
                                class="title font-weight-light font-italic text-h5 my-6 px-6"
                                align-text="center"
                              >
                                VAGAS ESGOTADAS
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col align="center">
                            <v-card color="grey" style="height: 100%">
                              <div class="title font-weight-black my-5 pt-5">
                                ATENÇÃO PARTICIPANTE <br />
                              </div>
                              <div
                                class="px-4"
                                style="font-size: 16px; line-height: 25px"
                                align="left"
                              >
                                SÓ É POSSÍVEL REALIZAR A INSCRIÇÃO PARA PARTICIPAÇÃO PRESENCIAL DE
                                UM TURNO ENTRE OS 03 DISPONÍVEIS, TENDO EM VISTA A CAPACIDADE MÁXIMA
                                DO LOCAL DE ATÉ 130 PESSOAS. AS PALESTRAS DOS OUTROS TURNOS PODERÃO
                                SER ACOMPANHAS NA PÁGINA DA SEDUC AO VIVO.
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-row justify="center" align="center">
                      <v-col cols="12" sm="4" class="my-2">
                        <v-img max-width="250" src="/img/i-congresso-educacao.png"></v-img>
                      </v-col>
                    </v-row>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Fechar</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-card
      class="mx-auto col-lg-12 mt-5"
      justify="center"
      align="center"
      v-else-if="inscricao_dados"
    >
      <h1>Você está inscrito(a) na turma:</h1>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="4" xs="12" class="my-4" justify="center" align="center">
          <v-img max-width="250" src="/img/i-congresso-educacao.png"></v-img>
        </v-col>
        <v-col cols="12" sm="8" xs="12" class="my-4" justify="center" align="center">
          <v-col align="center">
            <v-card color="#fbbb14" style="height: 100%">
              <div
                class="title font-weight-black my-5 pt-5"
                v-html="dados_evento.data[inscricao_dados.curso_id]"
              ></div>
              <div
                class="px-4"
                style="font-size: 16px; line-height: 25px"
                align="left"
                v-html="dados_evento.informacoes[inscricao_dados.curso_id]"
              ></div>
              <div
                class="title font-weight-light font-italic text-h5 my-6 px-6"
                align-text="center"
              >
                <div class="my-2 pb-4">
                  <v-btn small color="primary" dark @click="flag_desistir = true">
                    Desejo desistir! :(
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="dialog_local" persistent max-width="350">
        <v-card>
          <v-card-text class="text-h5">Você tem certeza que deseja se cadastrar?</v-card-text>
          <v-card-text>Caso seja sim, por favor escolha [SIM], caso contrário [NÃO].</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog_local = false"> NÃO </v-btn>
            <v-btn color="green darken-1" text @click="() => inscricao()"> SIM </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="flag_desistir" persistent max-width="350">
        <v-card>
          <v-card-text class="text-h5">Você tem certeza que deseja desistir?</v-card-text>
          <v-card-text>Caso seja sim, por favor escolha [SIM], caso contrário [NÃO].</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="flag_desistir = false"> NÃO </v-btn>
            <v-btn color="green darken-1" text @click="() => desistir_inscricao()"> SIM </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="space-around"> </v-row>
  </div>
</template>

<script>
export default {
  props: ["inscritos1", "inscritos2", "inscritos3", "inscricao_situacao", "inscricao_dados"],
  data: () => ({
    reveal: false,
    curso: null,
    dialog_local: false,
    dialog: false,
    flag_desistir: false,
    dados_evento: {
      data: [
        "",
        "1º DIA – 28/10 - MANHÃ<br />",
        "1º DIA – 28/10 - TARDE<br />",
        "2º DIA – 29/10 - TARDE<br />",
      ],
      informacoes: [
        "",
        "8:00h - CREDENCIAMENTO<br />9:00h - SOLENIDADE DE ABERTURA<br />10:00h – Palestra Magna: APRENDIZAGEM ATIVA E COLABORATIVA, PARA ALÉM DO CONTEÚDO – Ricardo Ramos Fragelli.<br />",
        "14:00h – CREDENCIAMENTO<br /> 15:00h – Palestra 2: SAÚDE MENTAL DO PROFESSOR E DO ALUNO - Thaís Faria Coelho. <br /> 16:00h – Palestra 3: O acolhimento de alunos e professores e o papel da família no retorno às aulas presenciais – Geraldo Peçanha.<br />",
        "14:00h - CREDENCIAMENTO<br />15:00h – A IMPORTÂNCIA DA NOVA EDUCAÇÃO – Jáder Montenegro.<br />16:00h – Palestra de Encerramento - A ESCOLA DO PRESENTE: motivação e novas competências para o ensino híbrido e presencial – Max Haetinger.<br /> 18:00h – Live de encerramento – Show Cultural. <br />",
      ],
    },
  }),
  methods: {
    async inscricao() {
      this.reveal = false;
      this.dialog = false;
      this.dialog_local = false;

      try {
        await this.$services.professoresService.inscricao({ curso_id: this.curso });
        this.$toast.success("Inscriçao realizada com sucesso");
        this.$emit("inscricao", true);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async desistir_inscricao() {
      this.flag_desistir = false;

      try {
        await this.$services.professoresService.deletarInscricao({ id: this.inscricao_dados.id });
        this.$toast.success("Inscriçao deletada com sucesso");
        this.$emit("inscricao", false);
      } catch (error) {
        this.$handleError(error);
      }
    },
    openDialog(opcao) {
      this.curso = opcao;
      this.dialog = false;
      this.dialog_local = true;
    },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
