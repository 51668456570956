<template>
  <main-template>
    <div>
      <v-alert color="error" border="left" elevation="2" colored-border>
        <v-icon color="error" size="64"> mdi-alert </v-icon>
        <v-row>
          <v-col class="grow"><strong>Avisos</strong> </v-col>
        </v-row>
        <div class="mt-1">
          <v-col class="grow">
            <v-list-item two-line>
              <v-list-item-content>
                <v-row class="px-3" justify="space-between">
                  <span class="d-inline-block title-1 font-weight-bold text-truncate box">
                    <v-col> Atenção Professores! </v-col>
                  </span>
                </v-row>
                <!-- Block level -->
                <div>
                  <v-col class="d-inline-block subtitle-1 font-weight-light text-truncate box">
                    O sistema estará disponível para registros de aulas e notas até o dia 23/12 às
                    23:59h. <br />
                    Após a data quem não registrou deve procurar a coordenação pedagógica.
                  </v-col>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </div>
      </v-alert>
    </div>
    <v-row>
      <v-col>
        <e-senha-fraca-alert v-if="senhaFraca" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <e-franquia-snippet-card :franquia="resumoFranquias" />
      </v-col>
    </v-row>
    <v-row v-if="user">
      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
        <v-card color="primary lighten-1 " :to="{ name: 'matriculas' }">
          <template slot="progress">
            <v-progress-linear
              color="secondary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-account-multiple </v-icon>
            <span class="title font-weight-light">Matriculas</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold">
            Total: {{ user.franquia.qtdMatriculas }} Matriculas
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
        <v-card :loading="carregandoTurmas" color="primary lighten-1" :to="{ name: 'turmas' }">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Turmas</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold">
            Total: {{ user.franquia.qtdTurmas }} Turmas
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
        <v-card
          :loading="carregandoFranquias"
          color="primary lighten-1 "
          @click="openSelecionarFranquiaModal = true"
        >
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-cogs </v-icon>
            <span class="title font-weight-light">Unid. escolares</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold">
            Total: {{ franquiasListInfo.length }} Unidades escolares
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="4" lg="4" v-if="aulasAguardandoConfirmacaoCount">
        <v-card color="primary lighten-1" :to="{ name: 'aulas.aguardandoConfirmacao' }">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-bag-personal </v-icon>
            <span class="title font-weight-light">Aulas</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold">
            Total: {{ aulasAguardandoConfirmacaoCount }} aguardando confirmação
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h4 mb-2">Listagem de Unidades escolares:</h2>
        <e-label>Pesquise uma unidade escolar aqui</e-label>
        <v-text-field
          autofocus
          solo
          hide-details
          v-model="query"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col
        v-for="franquia of franquiasPesquisadas.filter((f) => !f.deleted_at)"
        :key="franquia.id"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="4"
        align-self="stretch"
      >
        <e-franquia-snippet-card
          trocarDeFranquiaAoClicar
          :franquia="franquia"
          animated
          style="height: 100%"
        />
      </v-col>
    </v-row>
    <e-selecionar-franquia-modal v-model="openSelecionarFranquiaModal" />
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import searchQuery from "@/plugins/searchQuery";

export default {
  name: "HomePage",
  data() {
    return {
      query: "",
      openSelecionarFranquiaModal: false,
      aulasAguardandoConfirmacaoCount: null,
      carregandoTurmas: false,
      franquiasListInfo: [],
      carregandoFranquias: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["senhaFraca", "user"]),

    resumoFranquias() {
      const resumo = this.franquiasListInfo.reduce(
        (acc, current) => ({
          descricao: `RESUMO DAS SUAS UNIDADES ESCOLARES <small class="ml-2">[${this.franquiasListInfo.length}]</small>`,
          qtdTurmas: parseInt(acc.qtdTurmas, 10) + parseInt(current.qtdTurmas, 10),
          qtdAlunos: parseInt(acc.qtdAlunos, 10) + parseInt(current.qtdAlunos, 10),
          qtdMatriculas: parseInt(acc.qtdMatriculas, 10) + parseInt(current.qtdMatriculas, 10),
        }),
        {
          qtdTurmas: 0,
          qtdAlunos: 0,
          qtdMatriculas: 0,
        }
      );
      return resumo;
    },
    franquiasPesquisadas() {
      return this.franquiasListInfo.filter((franquia) =>
        searchQuery(null, this.query, franquia.descricao)
      );
    },
  },
  methods: {
    // ...mapActions("Franquias", ["loadInfoFranquias"]),
    async loadData() {
      if (this.franquiasListInfo.length === 0) {
        this.$loaderService.open("Carregando dados");
      }
      this.resumoAulas();

      this.franquiasListInfo = await this.$services.franquiasService.countInfoHome();
      this.$loaderService.close();
      this.$forceUpdate();
    },
    async resumoAulas() {
      try {
        const payload = await this.$services.aulasService.countBotoesHomer();
        this.aulasAguardandoConfirmacaoCount = payload;
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
  mounted() {
    this.loadData();
  },
  // async created() {
  //   await this.loadInfoFranquias();
  // },
};
</script>
