<template>
  <v-text-field
    :value="value"
    @input="(value) => $emit('input', value)"
    label="Selecione um horário"
    solo
    dense
    v-bind="$attrs"
    v-mask="'##:##'"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style></style>
