<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar color="primary">
          {{ matricula.aluno.nomecompleto.charAt(0) }}
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ matricula.aluno.nomecompleto }}</v-list-item-title>
        <v-list-item-subtitle>{{ matricula.turma.descricao }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    matricula: {
      type: Object,
      required: true,
    },
  },
};
</script>
