import Axios from "@/plugins/Axios";
import Parcela from "@/Models/Parcela";

export class ParcelasService {
  async baixarParcela(parcela, { mensalidades, juros, multa, desconto }) {
    const response = await Axios().post(`parcelas/${parcela.id}/baixar`, {
      mensalidades,
      juros,
      multa,
      desconto,
    });
    const parcelaAtualizada = new Parcela(response.data);
    Object.assign(parcela, parcelaAtualizada);
    return parcelaAtualizada;
  }
}
export default new ParcelasService();
