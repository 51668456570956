import Parcela from "./Parcela";
import Model from "./Model";

export default class Matricula extends Model {
  constructor(matricula) {
    super(matricula);
    Object.assign(this, matricula);
    if (matricula.parcelas)
      this.parcelas = matricula.parcelas.map((parcela) => new Parcela(parcela));
    if (this.aluno) {
      this.nomeAluno = this.aluno.nomecompleto;
    }
    if (this.turma) {
      this.nomeTurma = this.turma.descricao;
    }
  }
}
