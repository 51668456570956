<template>
  <v-dialog v-model="open" persistent max-width="450px">
    <v-card>
      <v-card-title />
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon size="75" color="warning"> fa fa-warning </v-icon>
          <span class="ml-4 text-body-2"
            >Ao fechar esse caixa ele não poderá mais ser aberto hoje. Tem certeza que deseja
            fechá-lo?</span
          >
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn text small :disabled="fechandoCaixa" color="error" @click="onClose">
          <v-icon small left> fa fa-close </v-icon>
          Cancelar
        </v-btn>

        <v-btn
          color="success white--text"
          depressed
          small
          :disabled="fechandoCaixa"
          :loading="fechandoCaixa"
          @click="() => fecharCaixa()"
        >
          <v-icon small left> fa fa-check </v-icon>
          Fechar caixa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import caixasService from "@/Services/CaixasService";

export default {
  name: "EFecharCaixaDialog",
  props: {
    caixa: {
      type: Object,
      default: null,
    },
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fechandoCaixa: false,
    };
  },
  methods: {
    async fecharCaixa() {
      try {
        this.fechandoCaixa = true;
        await caixasService.fecharCaixa(this.caixa);
        this.caixa.estaAberto = false;
        this.onClose();
        this.$toast.success("Caixa fechado com sucesso!!!");
      } catch (error) {
        this.$handleError(error);
      }
      this.fechandoCaixa = false;
    },
  },
};
</script>
