<template>
  <v-text-field
    v-mask="'##/##/####'"
    v-model="dataDeNascimento"
    :value="value"
    dense
    solo
    v-bind="$attrs"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      dataDeNascimento: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value(value) {
      if (value && value.match(/\d{4}-\d{2}-\d{2}/i)) {
        const [ano, mes, dia] = value.split("-");
        this.dataDeNascimento = `${dia}/${mes}/${ano}`;
      }
    },
    dataDeNascimento(value) {
      if (value && value.match(/\d{2}\/\d{2}\/\d{4}/i)) {
        const [dia, mes, ano] = value.split("/");
        if (parseInt(mes, 2) > 12 || parseInt(dia, 2) > 31) {
          this.$emit("change", null);
          this.date = null;
        }
        const dataFormatada = `${ano}-${mes}-${dia}`;
        this.date = new Date(dataFormatada);
        this.$emit("change", dataFormatada);
        return;
      }
      this.$emit("change", null);
      this.date = null;
    },
  },
};
</script>

<style></style>
