import Axios from "@/plugins/Axios";
import Aluno from "@/Models/Aluno";
import Matricula from "@/Models/Matricula";
// import store from "@/store";
class AlunosService {
  async syncAll() {
    const response = await Axios().get("alunos");
    // const data = response.data.map((aluno) => new Aluno(aluno));
    return response;
  }

  async syncAllFranquia() {
    const response = await Axios().get("alunos/list-franquia");
    const data = response.data.map((aluno) => new Aluno(aluno));
    return data;
  }

  async syncAllPaginate(pageNumber, query) {
    const response = await Axios().get(`alunos/listAllPaginate?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async pegarAluno(id) {
    const response = await Axios().get(`alunos/${id}`);
    const data = new Aluno(response.data);
    return data;
  }

  async criarAluno(aluno) {
    const response = await Axios().post("alunos", aluno);
    const novaAluno = new Aluno(response.data);
    return novaAluno;
  }

  async getAlunoComINEP(codigoInep) {
    const response = await Axios().get(`alunos/codigo-inep/${codigoInep}`);
    return response.data ? new Aluno(response.data) : null;
  }

  async atualizarAluno(aluno) {
    const response = await Axios().put(`alunos/${aluno.id}`, aluno);
    const alunoAtualizada = new Aluno(response.data);
    return alunoAtualizada;
  }

  async deletarAluno(aluno) {
    const response = await Axios().delete(`alunos/${aluno.id}`);
    const alunoDeletada = new Aluno(response.data);
    return alunoDeletada;
  }

  async recuperarAluno(aluno) {
    return this.atualizarAluno({ ...aluno, deleted_at: null });
  }

  async pesquisaAvancada(form) {
    const response = await Axios().post("alunos/pesquisa-avancada", form);
    const alunos = response.data.map((aluno) => new Aluno(aluno));
    return alunos;
  }

  async matriculasAluno(form) {
    const response = await Axios().post("alunos/matriculas-aluno", form);
    const matriculas = response.data.map((matricula) => new Matricula(matricula));
    return matriculas;
  }
}

export default new AlunosService();
