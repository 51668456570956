import Model from "./Model";

export default class Turma extends Model {
  constructor(franquia) {
    super();
    Object.assign(this, franquia);
    this.sistema_bncc_id = parseInt(this.sistema_bncc_id, 10);
    this.curso_id = parseInt(this.curso_id, 10);
    this.turno_id = parseInt(this.turno_id, 10);
    // this.tipo_de_atendimento = parseInt(this.tipo_de_atendimento, 10);
    // this.modalidade = parseInt(this.modalidade, 10);
  }
}
