<template>
  <label class="text-subtitle-1" v-bind="$props">
    <slot />
  </label>
</template>

<script>
export default {
  props: ["for"],
};
</script>

<style></style>
