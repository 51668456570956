<template>
  <v-card class="mx-auto my-3">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
    </template>

    <v-card-title>Aluno(a)</v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <div class="grey--text">{{ matricula ? matricula.aluno.nomecompleto : "- - -" }}</div>
      </v-row>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-row>
      <v-col cols="12" xs="12" sm="6">
        <v-card-title>Turma</v-card-title>
        <v-card-text>
          <v-chip>{{ matricula ? matricula.turma.descricao : "- - -" }}</v-chip>
        </v-card-text>
      </v-col>

      <v-col cols="12" xs="12" sm="6">
        <v-card-title>Avaliação do(a) aluno(a)</v-card-title>
        <v-card-text>
          <v-card-actions>
            <span class="grey--text" v-if="MediaDasMedias >= 0"> {{ MediaDasMedias }} </span>
            <v-rating
              :value="MediaDasMedias / 2"
              length="5"
              background-color="primary"
              color="primary accent-4"
              dense
              readonly
              size="32"
            ></v-rating>

            <v-spacer></v-spacer>
            <span class="grey--text mr-2">
              {{ emotions[(MediaDasMedias / 2).toFixed(0) - 1] }}</span
            >
          </v-card-actions>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["matricula", "notas"],
  data() {
    return {
      emotions: ["Péssimo(a)", "Ruim", "Mediano(a)", "Bom", "Excelente"],
      MediaDasMedias: 0,
      total: 0,
      arrayNotas: [],
    };
  },
  watch: {
    notas(value) {
      this.arrayNotas = value.data;
      this.calcularMediaDasMedias();
    },
  },
  methods: {
    calcularMediaDasMedias() {
      this.MediaDasMedias = _.mean(this.arrayNotas).toFixed(0);
    },
  },
};
</script>
