import Aviso from "@/Pages/Avisos/index.vue";
import NewEditAviso from "@/Pages/Avisos/newEditAvisos.vue";
import AvisosPortalAluno from "@/Pages/Avisos/avisosPortalAluno.vue";

export default [
  {
    path: "/avisos/index",
    name: "avisos.index",
    component: Aviso,
  },
  {
    path: "/avisos/portal",
    name: "avisos.portal",
    component: AvisosPortalAluno,
  },
  {
    path: "/avisos/create",
    name: "avisos.create",
    component: NewEditAviso,
  },
  {
    path: "/avisos/edit/:aviso_id",
    name: "avisos.edit",
    component: NewEditAviso,
    props: { editing: true },
  },
];
