<template>
  <v-dialog v-model="confirmDialog" max-width="290">
    <v-card>
      <v-card-title class="text-h5"> {{ objectModal.title }} </v-card-title>

      <v-card-text> {{ objectModal.message }} </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="() => this.$emit('changeModalConfirm')">
          Não
        </v-btn>

        <v-btn color="green darken-1" text @click="() => this.$emit('changeActionResponse')">
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    confirmDialog: {
      type: Boolean,
      require: true,
    },
    objectModal: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
