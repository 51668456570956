import Model from "./Model";

export default class Agenda extends Model {
  constructor(agenda) {
    super();
    Object.assign(this, agenda);
    this.expand = this.getExpand();
  }

  getExpand() {
    return false;
  }
}
