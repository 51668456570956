import Axios from "@/plugins/Axios";

export class NotifiqService {
  async rfid(cartao_rfid) {
    const response = await Axios().get(`confirmar-presenca/${cartao_rfid}`);
    return response.data;
  }

  async cadastrar_rfid(form_rfid) {
    const response = await Axios().post(`notifiq/matriculas/cadastrar/frid`, { form_rfid });
    return response.data;
  }
}
export default new NotifiqService();
