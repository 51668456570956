const newTheme = {
  primary: "#FB9716",
  secondary: "#EAB23C",
  rufous: "#212AD2",
  accent: "#E86413",
  error: "#f44336",
  warning: "#ff9800",
  info: "#03a9f4",
  success: "#FB9716",
  anchor: "red",
  white: "#fff",
  blue: "#212AD2",
  success_icon: "#4caf50",
};

export default newTheme;
