<template>
  <v-dialog :value="open" persistent max-width="450px">
    <v-card :loading="loading">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon size="75" :color="iconColor"> {{ iconName }} </v-icon>
          <span class="ml-4 text-body-2" v-html="message"></span>
        </div>
        <div class="d-flex flex-column pt-3 pb-3" v-if="confirmationCode">
          <span v-html="confirmationMessage"></span>
          <v-text-field autofocus v-model="confirmationInput" outlined dense />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-for="button of buttons"
          :key="buttons.indexOf(button)"
          v-bind="button.props || {}"
          :disabled="loading || (button.disableIfCodeDoesntMatch ? !confirmationCodeMatchs : false)"
          @click="() => button.callback()"
          type="button"
        >
          <v-icon v-if="button.icon" small left> {{ button.icon }} </v-icon>
          {{ button.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    message: "",
    title: "",
    iconName: "",
    iconColor: "",
    buttons: "",
    confirmationCode: null,
    confirmationInput: "",
    confirmationMessage: "",
    open: false,
    loading: false,
  }),
  computed: {
    confirmationCodeMatchs() {
      return this.confirmationCode === this.confirmationInput;
    },
  },
  mounted() {
    this.$modals.setCallbackFunction((options) => this.openModal(options));
    this.$modals.setCloseFunction(() => (this.open = false));
    this.$modals.setSetOptionsFunction((options) => this.setOptions(options));
  },
  methods: {
    openModal(options) {
      this.open = true;
      this.message = options.message;
      this.iconName = options.iconName;
      this.iconColor = options.iconColor;
      this.title = options.title;
      this.buttons = options.buttons;
      this.confirmationCode = options.confirmationCode ? `${options.confirmationCode}` : null;
      this.confirmationMessage = options.confirmationMessage;
      this.loading = options.loading;
      this.confirmationInput = "";
    },
    setOptions(options) {
      Object.keys(options).map((key) => (this[key] = options[key]));
    },
  },
};
</script>

<style></style>
