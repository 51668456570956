<template>
  <main-template>
    <v-container>
      <v-row>
        <v-col cols="12" />
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex mb-2 justify-space-between">
            <h1 class="text-h5">Gestão de Caixas</h1>
            <v-text-field
              v-model="search"
              class="search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
          <v-data-table
            :headers="tableHeaders"
            :items="caixas"
            :items-per-page="5"
            :loading="loadingCaixas"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:item.situacao="{ item: caixa }">
              <v-switch
                v-model="caixa.situacao"
                :disabled="atualizandoCaixa"
                dense
                :false-value="0"
                :true-value="1"
                flat
                @change="(novaSituacao) => toggleSituacaoCaixa(novaSituacao, caixa)"
              >
                <template v-slot:label>
                  <v-progress-circular
                    v-if="caixaSelecionado === caixa && atualizandoCaixa"
                    indeterminate
                    color="primary"
                    :width="3"
                    size="24"
                    class="ml-2"
                  />
                </template>
              </v-switch>
            </template>
            <template v-slot:item.estaAberto="{ item: caixa }">
              <v-chip
                v-if="caixa.estaAberto"
                small
                label
                color="success"
                :class="caixa.animationClass"
              >
                ABERTO
              </v-chip>
              <v-chip
                v-if="caixa.estaFechado"
                small
                label
                :class="caixa.animationClass"
                color="warning"
              >
                FECHADO
              </v-chip>
              <v-chip v-if="!caixa.estaAberto && !caixa.estaFechado" small label>
                SEM MOVIMENTAÇÃO
              </v-chip>
            </template>
            <template v-slot:item.responsavel="{ item: caixa }">
              <v-chip v-if="caixa.user" small label :class="caixa.animationClass" color="success">
                {{ caixa.user.name }}
              </v-chip>
              <v-chip v-if="!caixa.user" small label> - - - </v-chip>
            </template>

            <template v-slot:item.actions="{ item: caixa }">
              <v-tooltip v-if="$can(['caixas.*', 'caixas.abrir'])" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!(caixa.situacao && !caixa.estaAberto && !caixa.estaFechado)"
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openAbrirCaixaModal(caixa)"
                  >
                    <v-icon small> fa fa-shopping-cart </v-icon>
                  </v-btn>
                </template>
                <span>Abrir caixa</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$can(['caixas.*', 'caixas.fechar'])"
                    :disabled="!(caixa.situacao && caixa.estaAberto && !caixa.estaFechado)"
                    x-small
                    class="ml-1"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openFecharCaixaModal(caixa)"
                  >
                    <v-icon small> fa fa-cart-arrow-down </v-icon>
                  </v-btn>
                </template>
                <span>Fechar caixa</span> </v-tooltip
              ><v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="primary"
                    v-bind="attrs"
                    class="ml-1"
                    v-on="on"
                    @click="() => openHistoricoCaixaDrawer(caixa)"
                  >
                    <v-icon small> fa fa-history </v-icon>
                  </v-btn>
                </template>
                <span>Dados do caixa</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <e-abrir-caixa-dialog
      :on-close="closeAbrirCaixaModal"
      :open="abrirCaixaDialog.open"
      :caixa="abrirCaixaDialog.caixa"
      :valor-inicial="abrirCaixaDialog.valorInicial"
    />
    <e-fechar-caixa-dialog
      :on-close="closeFecharCaixaModal"
      :open="fecharCaixaDialog.open"
      :caixa="fecharCaixaDialog.caixa"
    />
    <e-historico-caixa v-model="historicoCaixaDrawer.open" :caixa="historicoCaixaDrawer.caixa" />
  </main-template>
</template>
<script>
import { mapGetters } from "vuex";
import EAbrirCaixaDialog from "../../components/Caixas/EAbrirCaixaDialog.vue";
import EFecharCaixaDialog from "../../components/Caixas/EFecharCaixaDialog.vue";
import EHistoricoCaixa from "../../components/Caixas/EHistoricoCaixa.vue";

export default {
  name: "Caixas",
  components: { EAbrirCaixaDialog, EFecharCaixaDialog, EHistoricoCaixa },
  data() {
    return {
      loadingCaixas: true,
      search: "",
      tableHeaders: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "descricao" },
        { text: "Ativo?", value: "situacao", align: "center" },
        { text: "Responsável", value: "responsavel", align: "center" },
        {
          text: "Situação",
          value: "estaAberto",
          sortable: false,
          align: "center",
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      atualizandoCaixa: false,
      caixaSelecionado: null,
      fecharCaixaDialog: {
        open: false,
        caixa: null,
      },
      abrirCaixaDialog: {
        open: false,
        valorInicial: 0,
        caixa: null,
      },
      historicoCaixaDrawer: {
        open: false,
        caixa: null,
      },
    };
  },
  computed: {
    ...mapGetters("Caixas", ["caixas"]),
  },
  mounted() {
    this.loadCaixas();
  },
  methods: {
    async toggleSituacaoCaixa(novaSituacao, caixa) {
      this.atualizandoCaixa = true;
      this.caixaSelecionado = caixa;
      try {
        const caixaAtualizado = await this.$services.caixasService.update({
          ...caixa,
          situacao: novaSituacao,
        });
        caixa.situacao = caixaAtualizado.situacao;
        this.$toast.success("Caixa atualizado com sucesso!");
      } catch (error) {
        this.$handleError(error);
        caixa.situacao = !novaSituacao;
      }
      this.caixaSelecionado = null;
      this.atualizandoCaixa = false;
    },
    async openAbrirCaixaModal(caixa) {
      this.abrirCaixaDialog = {
        open: true,
        valorInicial: 0,
        caixa,
      };
    },
    async closeAbrirCaixaModal() {
      this.abrirCaixaDialog = {
        open: false,
        valorInicial: 0,
        caixa: null,
      };
    },
    async openFecharCaixaModal(caixa) {
      this.fecharCaixaDialog = {
        open: true,
        caixa,
      };
    },
    async closeFecharCaixaModal() {
      this.fecharCaixaDialog = {
        open: false,
        caixa: null,
      };
    },
    async loadCaixas() {
      this.loadingCaixas = true;
      try {
        await this.$services.caixasService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
      this.loadingCaixas = false;
    },
    openHistoricoCaixaDrawer(caixa) {
      this.historicoCaixaDrawer = {
        caixa,
        open: true,
      };
    },
  },
};
</script>
