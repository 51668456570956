const state = () => ({
  menuOpen: true,
});
const mutations = {
  setMenuOpen: (state, menuOpen) => (state.menuOpen = menuOpen),
};
const actions = {};
const getters = {
  menuOpen: (state) => state.menuOpen,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
