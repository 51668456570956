<template>
  <div class="d-flex align-center">
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-currency-field
        :default-value="null"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :decimal-length="1"
        :max="max"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{
          'min-width': '60px !important',
          'max-width': '60px !important',
        }"
        :disabled="true"
      />
    </template>
    <template v-else>
      <v-currency-field
        :default-value="null"
        @input="(val) => handleInput(val)"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :max="max"
        :decimal-length="1"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{ 'min-width': '60px !important', 'max-width': '60px !important' }"
        :disabled="disabled"
      />
      <!-- :maxlength="2" -->
      <v-btn
        icon
        small
        v-show="value === null && value !== undefined && !disabled"
        color="error"
        class="red lighten-5 ml-2"
        @click="emitNull"
      >
        <v-icon x-small>fa fa-close</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleInput(val) {
      const notasSplit = val.toString();
      const arr = notasSplit.split(".");

      if (arr[0] !== "0") {
        if (arr[1] === "0" || arr[1] === "1" || arr[1] === "2") {
          val = `${arr[0]}.${0}`;
        } else if (
          arr[1] === "3" ||
          arr[1] === "4" ||
          arr[1] === "5" ||
          arr[1] === "6" ||
          arr[1] === "7"
        ) {
          val = `${arr[0]}.${5}`;
        } else if (arr[1] === "8" || arr[1] === "9") {
          const result = parseInt(arr[0], 10) + 1;
          val = `${result}.0`;
        }
      }

      if (val > this.max) {
        this.$emit("change", parseFloat(val, 10));
        this.$refs.input.$emit("input", this.max);
        this.$emit("input", parseFloat(val, 10));
      } else if (val < this.min) {
        this.$refs.input.$emit("input", this.min);
      } else {
        this.$emit("input", parseFloat(val, 2));
        this.$emit("change", parseFloat(val, 2));
      }

      // if (val > this.max) {
      //   this.$refs.input.$emit("input", this.max);
      // } else if (val < this.min) {
      //   this.$refs.input.$emit("input", this.min);
      // } else {
      //   this.$emit("input", parseFloat(val, 10));
      //   this.$emit("change", parseFloat(val, 10));
      // }
    },
    emitNull() {
      this.$emit("input", undefined);
      this.$emit("change", undefined);
      setTimeout(() => {
        // eslint-disable-next-line
        this.$refs.input._data.formattedValue = "";
      }, 150);
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>
