import MapCollection from "collections/map";
import Vue from "vue";
import sistemaBNCCService from "@/Services/SistemaBNCCService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  sistemaBNCC: [],
  carregandoSistemaBNCC: false,
  sistemaBNCCLoaded: false,
  sistemaBNCCList: [],
});
const mutations = {
  setSistemaBNCC(state, sistema_bncc) {
    const sistemaBNCC = new MapCollection();
    sistema_bncc.sistema.map((sistema) => sistemaBNCC.set(sistema.id, sistema));
    Vue.set(state, "sistemaBNCC", sistemaBNCC);
    Vue.set(state, "sistemaBNCCList", Array.from(sistemaBNCC.values()));

    state.sistemaBNCCLoaded = true;
    // state = {...state, sistemaBNCC: sistemaBNCCMap, sistemaBNCCLoaded: true}
  },
  setCarregandoSistemaBNCC(state, newValue) {
    state.carregandoSistemaBNCC = newValue;
  },
  adicionarTipoDeEnsino(state, sistemaBNCC) {
    state.sistemaBNCC.set(sistemaBNCC.id, sistemaBNCC);
  },
  atualizarTipoDeEnsino(state, sistemaBNCC) {
    state.sistemaBNCC.set(sistemaBNCC.id, sistemaBNCC);
  },
  deletarTipoDeEnsino(state, sistemaBNCCId) {
    state.sistemaBNCC.delete(sistemaBNCCId);
  },
};
const actions = {
  async loadSistemaBNCC({ commit }) {
    commit("setCarregandoSistemaBNCC", true);
    try {
      const sistemaBNCCList = await sistemaBNCCService.listarTodos();
      commit("setSistemaBNCC", sistemaBNCCList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSistemaBNCC", false);
  },
};
const getters = {
  sistemaBNCC: (state) => state.sistemaBNCC,
  carregandoSistemaBNCC: (state) => state.carregandoSistemaBNCC,
  sistemaBNCCLoaded: (state) => state.sistemaBNCCLoaded,
  sistemaBNCCList: (state) => state.sistemaBNCCList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
