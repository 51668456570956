<template>
  <main-template>
    <v-list flat>
      <v-subheader>REPORTS</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="user of users" :key="user.id">
          <v-list-item-content>
            <v-list-item-title>
              <span
                ><strong>{{ user.name }}</strong> - {{ user.email }}</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </main-template>
</template>

<script>
import { request, gql } from "graphql-request";

export default {
  name: "ListarUsuariosPage",
  data() {
    return {
      users: [],
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      const response = await request(
        "http://localhost:8000/graphql",
        gql`
          query {
            users {
              id
              name
              email
            }
          }
        `
      );
      this.users = response.users;
    },
  },
};
</script>

<style lang="scss" scoped></style>
