import MapCollection from "collections/map";
import Vue from "vue";
import professoresService from "@/Services/ProfessoresService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  professores: [],
  carregandoProfessores: false,
  professoresLoaded: false,
  professoresList: [],
  paginate: null,
});
const mutations = {
  setProfessores(state, professores) {
    const professoresMap = new MapCollection();
    professores.map((professor) => professoresMap.set(professor.id, professor));
    Vue.set(state, "professores", professoresMap);
    Vue.set(state, "professoresLoaded", true);
    Vue.set(state, "professoresList", Array.from(state.professores.values()));
  },
  setCarregandoProfessores(state, newValue) {
    state.carregandoProfessores = newValue;
  },
  adicionarProfessor(state, professor) {
    state.professores.set(professor.id, professor);
    Vue.set(state, "professoresList", Array.from(state.professores.values()));
    Vue.set(state, "professores", state.professores);
  },
  atualizarProfessor(state, professor) {
    state.professores.set(professor.id, professor);
    Vue.set(state, "professoresList", Array.from(state.professores.values()));
    Vue.set(state, "professores", state.professores);
  },
  deletarProfessor(state, professorId) {
    state.professores.delete(professorId);
    Vue.set(state, "professoresList", Array.from(state.professores.values()));
    Vue.set(state, "professores", state.professores);
  },
  setPaginate(state, data) {
    Vue.set(state, "paginate", data);
  },
};
const actions = {
  async loadProfessores({ commit }, options = {}) {
    commit("setCarregandoProfessores", true);
    try {
      // const professoresList = await professoresService.syncAll(options.force);
      const professoresList = await professoresService.syncAllPaginate(
        options.pageNumber,
        options.query
      );
      commit("setProfessores", professoresList.data.data);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoProfessores", false);
  },
  async getPaginate({ commit }, options = {}) {
    const professoresList = await professoresService.syncAllPaginate(
      options.pageNumber,
      options.query
    );
    commit("setPaginate", professoresList.data);
  },
};
const getters = {
  professores: (state) => state.professores,
  carregandoProfessores: (state) => state.carregandoProfessores,
  professoresLoaded: (state) => state.professoresLoaded,
  professoresList: (state) => state.professoresList,
  paginate: (state) => state.paginate,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
