import MapCollection from "collections/map";
import Vue from "vue";
import disciplinasService from "@/Services/DisciplinasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  disciplinas: [],
  carregandoDisciplinas: false,
  disciplinasLoaded: false,
  disciplinasList: [],
});
const mutations = {
  setDisciplinas(state, disciplinas) {
    const disciplinasMap = new MapCollection();
    disciplinas.map((disciplina) => disciplinasMap.set(disciplina.id, disciplina));
    state.disciplinas = disciplinasMap;
    Vue.set(state, "disciplinas", disciplinasMap);
    Vue.set(state, "disciplinasList", Array.from(disciplinasMap.values()));
    Vue.set(state, "disciplinasLoaded", true);
    // state.disciplinasLoaded = true;
    // state.disciplinasList = Array.from(state.disciplinas.values());
    // state = {...state, disciplinas: disciplinasMap, disciplinasLoaded: true}
  },
  setCarregandoDisciplinas(state, newValue) {
    state.carregandoDisciplinas = newValue;
  },
  adicionarDisciplina(state, disciplina) {
    state.disciplinas.set(disciplina.id, disciplina);
    Vue.set(state, "disciplinas", state.disciplinas);
    Vue.set(state, "disciplinasList", Array.from(state.disciplinas.values()));
  },
  atualizarDisciplina(state, disciplina) {
    state.disciplinas.set(disciplina.id, disciplina);
    Vue.set(state, "disciplinas", state.disciplinas);
    Vue.set(state, "disciplinasList", Array.from(state.disciplinas.values()));
  },
  deletarDisciplina(state, disciplinaId) {
    state.disciplinas.delete(disciplinaId);
    Vue.set(state, "disciplinas", state.disciplinas);
    Vue.set(state, "disciplinasList", Array.from(state.disciplinas.values()));
  },
};
const actions = {
  async loadDisciplinas({ commit }, options = {}) {
    commit("setCarregandoDisciplinas", true);
    try {
      const disciplinasList = await disciplinasService.syncAll(options.force);
      commit("setDisciplinas", disciplinasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoDisciplinas", false);
  },
};
const getters = {
  disciplinas: (state) => state.disciplinas,
  carregandoDisciplinas: (state) => state.carregandoDisciplinas,
  disciplinasLoaded: (state) => state.disciplinasLoaded,
  disciplinasList: (state) => state.disciplinasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
