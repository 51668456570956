<template>
  <div>
    <v-app id="mainApp">
      <v-main>
        <v-toast />
        <router-view />
      </v-main>
    </v-app>
    <e-modal />
    <e-loader />
  </div>
</template>

<script>
import { VToast } from "vuetify-snackbar-toast";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: { VToast },
  methods: {
    ...mapActions("Auth", ["verificarForcaDaSenha"]),
  },
  mounted() {
    document.title = process.env.VUE_APP_PAGE_TITLE;
    this.$loaderService.loadBasicDataIfNeeded();
    // this.$services.authService.verificarForcaDaSenha();
    this.verificarForcaDaSenha();
  },
};
</script>
<style scoped>
#mainApp {
  background-color: #efefef;
}
</style>
