<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip color="primary" label small v-bind="attrs" v-on="on"
        >{{ gestaoDeAula.qntConfirmada ? gestaoDeAula.qntConfirmada : 0 }}/{{
          gestaoDeAula.qntAulas
        }}</v-chip
      >
    </template>
    <span>
      <div v-if="gestaoDeAula.qntConfirmada">Confirmadas: {{ gestaoDeAula.qntConfirmada }}</div>
      <div v-if="gestaoDeAula.qntConflito">Conflitadas: {{ gestaoDeAula.qntConflito }}</div>
      <div v-if="gestaoDeAula.qntInvalida">Inválidas: {{ gestaoDeAula.qntInvalida }}</div>
      <div v-if="gestaoDeAula.qntAguardandoConfirmacao">
        Aguardando confirmação: {{ gestaoDeAula.qntAguardandoConfirmacao }}
      </div>
      <div v-if="gestaoDeAula.qntInvalida">Faltas: {{ gestaoDeAula.qntInvalida }}</div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    gestaoDeAula: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
