<template>
  <span
    v-bind:class="{
      'red--text': valueAsNumberOrNull && valueAsNumberOrNull < mediaMinima,
      'success--text': valueAsNumberOrNull && valueAsNumberOrNull >= mediaMinima,
    }"
    class="font-weight-bold"
    >{{ !valueIsEmpty ? valueAsNumberOrNull.toFixed(1) : "- - -" }}</span
  >
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    mediaMinima: {
      type: [Number],
      default: 7,
    },
  },
  computed: {
    valueAsNumberOrNull() {
      return !this.valueIsEmpty ? parseFloat(this.value, 10) : null;
    },
    valueIsEmpty() {
      return this.value === null || this.value === undefined;
    },
  },
};
</script>
