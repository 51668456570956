import MapCollection from "collections/map";
import Vue from "vue";
import seriesService from "@/Services/SeriesService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  series: [],
  carregandoSeries: false,
  seriesLoaded: false,
  seriesList: [],
});
const mutations = {
  setSeries(state, series) {
    const seriesMap = new MapCollection();
    series.map((serie) => seriesMap.set(serie.id, serie));
    Vue.set(state, "series", seriesMap);
    Vue.set(state, "seriesList", Array.from(seriesMap.values()));
    Vue.set(state, "seriesLoaded", true);
    // state = {...state, series: seriesMap, seriesLoaded: true}
  },
  setCarregandoSeries(state, newValue) {
    state.carregandoSeries = newValue;
  },
  adicionarSerie(state, serie) {
    state.series.set(serie.id, serie);
    Vue.set(state, "series", state.series);
    Vue.set(state, "seriesList", Array.from(state.series.values()));
  },
  atualizarSerie(state, serie) {
    state.series.set(serie.id, serie);
    Vue.set(state, "series", state.series);
    Vue.set(state, "seriesList", Array.from(state.series.values()));
  },
  deletarSerie(state, serieId) {
    state.series.delete(serieId);
    Vue.set(state, "series", state.series);
    Vue.set(state, "seriesList", Array.from(state.series.values()));
  },
};
const actions = {
  async loadSeries({ commit }, options = {}) {
    commit("setCarregandoSeries", true);
    try {
      const seriesList = await seriesService.syncAll(options.force);
      commit("setSeries", seriesList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSeries", false);
  },
};
const getters = {
  series: (state) => state.series,
  carregandoSeries: (state) => state.carregandoSeries,
  seriesLoaded: (state) => state.seriesLoaded,
  seriesList: (state) => state.seriesList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
