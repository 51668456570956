import SeriesIndexPage from "@/Pages/Series/index.vue";
import NewEditSeriePage from "@/Pages/Series/NewEditSeriePage.vue";

export default [
  {
    path: "/series/",
    name: "series",
    component: SeriesIndexPage,
  },
  {
    path: "/series/create",
    name: "series.create",
    component: NewEditSeriePage,
    props: { editing: false },
  },
  {
    path: "/series/edit/:serie_id",
    name: "series.edit",
    component: NewEditSeriePage,
    props: { editing: true },
  },
];
