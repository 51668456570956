import UsuariosIndexPage from "@/Pages/Usuarios/index.vue";
import NewEditUsuarioPage from "@/Pages/Usuarios/NewEditUsuarioPage.vue";
import listaDeUsuariosParaBloquearLiberar from "@/Pages/Usuarios/listaDeUsuariosParaBloquearLiberar.vue";

export default [
  {
    path: "/usuarios/",
    name: "usuarios",
    component: UsuariosIndexPage,
  },
  {
    path: "/usuarios/create",
    name: "usuarios.create",
    component: NewEditUsuarioPage,
    props: { editing: false },
  },
  {
    path: "/usuarios/edit/:usuario_id",
    name: "usuarios.edit",
    component: NewEditUsuarioPage,
    props: { editing: true },
  },
  {
    path: "/usuarios/listaDeUsuariosParaBloquearLiberar",
    name: "usuarios.listaDeUsuariosParaBloquearLiberar",
    component: listaDeUsuariosParaBloquearLiberar,
  },
];
