import MapCollection from "collections/map";
import Vue from "vue";
import alunosService from "@/Services/AlunosService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  alunos: [],
  carregandoAlunos: false,
  alunosLoaded: false,
  alunosList: [],
});
const mutations = {
  setAlunos(state, alunos) {
    const alunosMap = new MapCollection();
    alunos.map((aluno) => alunosMap.set(aluno.id, aluno));
    Vue.set(state, "alunos", alunosMap);
    Vue.set(state, "alunosList", Array.from(alunosMap.values()));
    Vue.set(state, "alunosLoaded", true);
    // state = {...state, alunos: alunosMap, alunosLoaded: true}
  },
  setCarregandoAlunos(state, newValue) {
    state.carregandoAlunos = newValue;
  },
  adicionarAluno(state, aluno) {
    state.alunos.set(aluno.id, aluno);
    Vue.set(state, "alunos", state.alunos);
    Vue.set(state, "alunosList", Array.from(state.alunos.values()));
  },
  atualizarAluno(state, aluno) {
    state.alunos.set(aluno.id, aluno);
    Vue.set(state, "alunos", state.alunos);
    Vue.set(state, "alunosList", Array.from(state.alunos.values()));
  },
  deletarAluno(state, alunoId) {
    state.alunos.delete(alunoId);
    Vue.set(state, "alunos", state.alunos);
    Vue.set(state, "alunosList", Array.from(state.alunos.values()));
  },
};
const actions = {
  async loadAlunos({ commit }, options = {}) {
    commit("setCarregandoAlunos", true);
    try {
      const alunosList = await alunosService.syncAll(options.force);
      commit("setAlunos", alunosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoAlunos", false);
  },
};
const getters = {
  alunos: (state) => state.alunos,
  carregandoAlunos: (state) => state.carregandoAlunos,
  alunosLoaded: (state) => state.alunosLoaded,
  alunosList: (state) => state.alunosList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
