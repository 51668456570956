import DisciplinasIndexPage from "@/Pages/Disciplinas/index.vue";
import NewEditDisciplinaPage from "@/Pages/Disciplinas/NewEditDisciplinaPage.vue";

export default [
  {
    path: "/disciplinas/",
    name: "disciplinas",
    component: DisciplinasIndexPage,
  },
  {
    path: "/disciplinas/create",
    name: "disciplinas.create",
    component: NewEditDisciplinaPage,
    props: { editing: false },
  },
  {
    path: "/disciplinas/edit/:disciplina_id",
    name: "disciplinas.edit",
    component: NewEditDisciplinaPage,
    props: { editing: true },
  },
];
