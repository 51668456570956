<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" persistent>
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="error" @click="changeSheet"> Fechar </v-btn>
        <div class="py-3">
          Aluno:
          <strong>{{ matricula.aluno_id ? matricula.aluno.nomecompleto : "[- - -]" }}</strong
          >. <br />Está devidamente matriculado na Unidade Escolar:
          <strong>{{ matricula.turma ? matricula.turma.franquia.descricao : "[- - -]" }}</strong
          >,
          <br />
          Por favor entre em contato com o responável da Unidade Escolar.
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  props: {
    sheet: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      default: null,
    },
  },
  methods: {
    changeSheet() {
      this.$emit("changeSheet", false);
    },
  },
};
</script>
