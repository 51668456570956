const state = () => ({
  caixaSelecionado: null,
  caixas: [],
});
const mutations = {
  clearStore(state) {
    Object.assign(state, {});
  },
  setCaixaSelecionado(state, caixa) {
    state.caixaSelecionado = caixa;
    state.parcelaSelecionado = null;
  },
  setCaixas(state, caixas) {
    state.caixas = caixas;
  },
};
const actions = {};
const getters = {
  caixaSelecionado: (state) => state.caixaSelecionado,
  caixas: (state) => state.caixas,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
