<template>
  <v-chip :color="color" class="text-uppercase white--text" label small>
    {{ parcela.situacaoEmExtenso }}
  </v-chip>
</template>

<script>
import Parcela from "@/Models/Parcela";

export default {
  props: {
    parcela: {
      type: Parcela,
      required: true,
    },
  },
  computed: {
    color() {
      if (this.parcela.estaNegociada) return "deep-purple lighten-1";
      if (this.parcela.estaEmAberto) return "warning";
      if (this.parcela.estaPaga) return "success";
      return "default";
    },
  },
};
</script>

<style></style>
