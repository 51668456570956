import MapCollection from "collections/map";
import turnosService from "@/Services/TurnosService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  turnos: [],
  carregandoTurnos: false,
  turnosLoaded: false,
  turnosList: [],
});
const mutations = {
  setTurnos(state, turnos) {
    const turnosMap = new MapCollection();
    turnos.map((turno) => turnosMap.set(turno.id, turno));
    state.turnos = turnosMap;
    state.turnosLoaded = true;
    // state = {...state, turnos: turnosMap, turnosLoaded: true}
  },
  setCarregandoTurnos(state, newValue) {
    state.carregandoTurnos = newValue;
  },
  adicionarTurno(state, turno) {
    state.turnos.set(turno.id, turno);
  },
  atualizarTurno(state, turno) {
    state.turnos.set(turno.id, turno);
  },
  deletarTurno(state, turnoId) {
    state.turnos.delete(turnoId);
  },
};
const actions = {
  async loadTurnos({ commit }, options = {}) {
    commit("setCarregandoTurnos", true);
    try {
      const turnosList = await turnosService.syncAll(options.force);
      commit("setTurnos", turnosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoTurnos", false);
  },
};
const getters = {
  turnos: (state) => state.turnos,
  carregandoTurnos: (state) => state.carregandoTurnos,
  turnosLoaded: (state) => state.turnosLoaded,
  turnosList: (state) => Array.from(state.turnos.values()),
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
