<template>
  <div class="pa-2">
    <v-card align="center" class="pa-3">
      <v-card-text>
        <!-- <h6 class="text-body-1 font-weight-bold text-black">
                  SITUAÇÃO CAIXA:
                </h6> -->
        <span v-if="!user">Carregando...</span>
        <div v-if="user && !user.caixa" class="ml-2">
          <v-btn color="secondary" small :to="{ name: 'caixas' }"> ABRIR CAIXA </v-btn>
        </div>
        <div v-if="user && user.caixa" class="ml-2 text-uppercase">
          <div class="d-flex justify-space-around align-center">
            <div class="d-flex flex-column">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ user.caixa.descricao | truncate(9) }}</span>
                </template>
                <span>{{ user.caixa.descricao }}</span>
              </v-tooltip>
              <v-chip label color="green" x-small> ABERTO </v-chip>
            </div>
            <v-btn color="error" @click="openFecharCaixaDialog">
              <v-icon small> fa fa-cart-arrow-down </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <e-fechar-caixa-dialog
      :open="fecharCaixaDialog.open"
      :caixa="fecharCaixaDialog.caixa"
      :on-close="closeFecharCaixaDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EFecharCaixaDialog from "../../../components/Caixas/EFecharCaixaDialog.vue";
// import MainTemplateMenu from './MainTemplateMenu.vue';

export default {
  name: "ESituacaoCaixa",
  components: { EFecharCaixaDialog },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      fecharCaixaDialog: {
        open: false,
        caixa: null,
      },
    };
  },
  methods: {
    openFecharCaixaDialog() {
      this.fecharCaixaDialog = {
        caixa: this.user.caixa,
        open: true,
      };
    },
    closeFecharCaixaDialog() {
      this.fecharCaixaDialog = {
        caixa: null,
        open: false,
      };
    },
  },
};
</script>
