<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Mensal
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row>
          <v-col cols="7">
            <div v-for="(el, index) of $constants.planosMensaisInfantil" :key="index" no-gutters>
              <div v-show="tab_atual == index">
                <e-label>{{ el.descricao }}</e-label>
                <v-textarea filled name="input-7-4" solo dense rows="15" v-model="form[el.form]" />
              </div>
            </div>
            <!-- <v-chip-group v-model="tab_atual" active-class="indigo--text text--accent-4" mandatory>
              <v-chip outlined v-for="(el, index) of $constants.planosForm" :key="index">{{
                el.descricao
              }}</v-chip>
            </v-chip-group> -->
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-col>
          <e-skeleton v-if="loadForm" />

          <v-col v-else cols="5" class="pt-10">
            <v-select
              :items="meses"
              item-text="descricao"
              item-value="id"
              v-model="selectMonth"
              return-object
              label="Selecione um mês"
              solo
            ></v-select>
            <template>
              <v-col>
                <v-row justify="center">
                  <v-expansion-panels accordion>
                    <v-expansion-panel
                      v-for="(item, i) in $constants.planosMensaisInfantil"
                      :key="i"
                    >
                      <v-expansion-panel-header
                        @click="tab_atual = i"
                        :class="
                          form[item.form]
                            ? 'green lighten-4'
                            : tab_atual === i
                            ? 'indigo lighten-4'
                            : ''
                        "
                        >{{ item.descricao }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content
                        :class="
                          form[item.form]
                            ? 'green lighten-4'
                            : tab_atual === i
                            ? 'indigo lighten-4'
                            : ''
                        "
                      >
                        {{ form[item.form] }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-col>
            </template>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
    <v-alert
      v-show="formRequest"
      class="my-2 animate__animated animate__headShake"
      dense
      border="left"
      type="warning"
    >
      Preencha todo o questionário
    </v-alert>
  </main-template>
</template>

<script>
// import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  watch: {
    form() {
      this.formRequest = false;
    },
    selectMonth() {
      this.selectPlano();
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      meses: [],
      selectMonth: {
        id: 3,
        descricao: "Março",
      },
      submittingForm: false,
      formRequest: false,
      loadForm: false,
      form: {
        mes_id: 3,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      progress: 90,
      tab_atual: 0,
    };
  },
  methods: {
    setForm() {},
    async selectPlano() {
      this.loadForm = true;
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { id } = this.selectMonth;
        const response = await this.$services.planosBimestraisService.planoMensalInfantil(
          gestaoDeAulaId,
          id
        );
        this.loadForm = false;
        if (response !== "") {
          this.form = response;
          return;
        }
        this.form = {
          mes_id: id,
        };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadForm = false;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      this.formRequest = false;
      try {
        await this.$constants.planosMensaisInfantil.map((item) => {
          if (!this.form[item.form] || this.form[item.form] === "") {
            this.formRequest = true;
          }
          return item;
        });
        if (this.formRequest) {
          this.$loaderService.close();
          return;
        }
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.mes_id = this.selectMonth.id;
        await this.$services.planosBimestraisService.planoMensalInfantilCreateOrUpdate(this.form);
        this.$toast.success("Plano mensal salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      const { mes_id } = this.form;
      try {
        this.$loaderService.open("Carregando dados...");
        const json = await this.$services.gestoesDeAulasService.visualizarParaPlano(gestaoDeAulaId);
        this.gestaoDeAula = json.gestoesDeAulas;
        this.meses = json.meses;
        const response = await this.$services.planosBimestraisService.planoMensalInfantil(
          gestaoDeAulaId,
          mes_id
        );
        if (response !== "") {
          this.form = response;
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
