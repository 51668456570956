<template>
  <v-autocomplete
    :filter="searchQuery"
    hide-no-data
    solo
    :value="value"
    @change="(val) => $emit('change', val)"
    v-bind="{ ...$props }"
  />
</template>

<script>
import searchQuery from "@/plugins/searchQuery";

export default {
  props: [
    "name",
    "items",
    "loading",
    "disabled",
    "item-text",
    "filter",
    "label",
    "clearable",
    "return-object",
    "dense",
    "cache-items",
    "hide-no-data",
    "solo",
    "autofocus",
    "item-value",
    "value",
    "error-messages",
    "multiple",
    "chips",
    "small-chips",
    "readonly",
    "no-data-text",
  ],
  model: {
    prop: "value",
    event: "change",
  },
  data: () => ({
    searchQuery,
  }),
};
</script>

<style></style>
