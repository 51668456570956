<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'matriculas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Matrícula
        </h1>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row v-for="matricula of form.matriculas" :key="form.matriculas.indexOf(matricula)">
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Aluno</e-label>
            <e-autocomplete
              :items="alunosList"
              :return-object="false"
              :item-value="(aluno) => aluno.id"
              :item-text="(aluno) => aluno.nomecompleto"
              :loading="carregandoAlunos"
              :readonly="editing"
              v-model="matricula.aluno_id"
              label="Selecione uma opção"
              dense
              solo
              @change="(matricula) => verificarSeAlunoPossuiMatriculaNoMunicipio(matricula)"
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Turma</e-label>
            <e-autocomplete
              :items="turmasList"
              :return-object="true"
              :item-text="(turma) => turma.descricao"
              :loading="carregandoTurmas"
              v-model="matricula.turma"
              @change="(turma) => changeTurma(matricula, turma)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Série</e-label>
            <e-autocomplete
              :items="matricula.turma ? matricula.turma.series : []"
              :return-object="false"
              :item-value="(serie) => serie.id"
              :item-text="(serie) => serie.descricao"
              :disabled="!matricula.turma"
              v-model="matricula.serie_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="form.matriculas.length > 1"
              class="mt-8"
              color="error"
              @click="
                () =>
                  (form.matriculas = form.matriculas.filter(
                    (matriculaObj) => matriculaObj !== matricula
                  ))
              "
              small
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="disabled || submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn class="ml-3" v-if="!editing" color="secondary" @click="adicionarMatricula">
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>

    <e-sheet-aviso-matricula-existente
      :sheet="sheet"
      :matricula="matricula"
      @changeSheet="sheet = $event"
    ></e-sheet-aviso-matricula-existente>
  </main-template>
</template>

<script>
import ESheetAvisoMatriculaExistente from "../../components/Matriculas/ESheetAvisoMatriculaExistente.vue";
import ELabel from "../../components/ELabel.vue";
import Aluno from "../../Models/Aluno";

export default {
  components: {
    ELabel,
    ESheetAvisoMatriculaExistente,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  mounted() {
    this.listarMatriculas();
    this.loadData();
    this.loadFormData();
  },

  data() {
    return {
      disabledBtn: true,
      submittingForm: false,
      series: [],
      form: { matriculas: [{}] },

      carregandoAlunos: false,
      alunosList: [],

      carregandoTurmas: false,
      turmasList: [{}],

      carregandoMatriculas: false,
      matriculas: [{}],
      matriculasList: [{}],

      disabled: true,
      sheet: false,
      matricula: {},
    };
  },
  methods: {
    changeTurma(matricula, turma) {
      matricula.turma_id = turma.id;
      matricula.serie_id = turma.series[0].id;
    },

    async listarMatriculas() {
      this.carregandoMatriculas = true;
      try {
        const response = await this.$services.matriculasService.all();
        this.matriculasList = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoMatriculas = false;
    },

    async loadTurmas() {
      this.carregandoTurmas = true;
      try {
        const response = await this.$services.turmasService.syncAll();
        this.turmasList = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoTurmas = false;
    },

    async listarAlunos() {
      this.carregandoAlunos = true;
      try {
        const response = await this.$services.alunosService.syncAll();
        this.alunosList = response.data.map((aluno) => new Aluno(aluno));

        if (this.editing) {
          const { matricula_id } = this.$route.params;
          const id = parseInt(matricula_id, 10);
          this.form.matriculas = this.matriculasList.filter((matricula) => matricula.id === id);
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoAlunos = false;
    },

    adicionarMatricula() {
      this.form.matriculas.push({});
    },

    async verificarSeAlunoPossuiMatriculaNoMunicipio(matricula) {
      try {
        await this.$services.matriculasService
          .verificarSeAlunoPossuiMatriculaNoMunicipio(matricula)
          .then((response) => {
            if (response === "") {
              this.sheet = false;
              return (this.disabled = false);
            }

            this.form.matriculas = this.form.matriculas.filter(
              (matriculaObj) => matriculaObj.aluno_id !== matricula
            );
            this.matricula = response;
            this.sheet = true;
            return (this.disabled = true);
          })
          .catch(() => {
            this.disabled = true;
          });
      } catch (error) {
        this.$handleError(error);
      }
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.matriculasService.atualizarMatricula(this.form.matriculas[0]);
        } else {
          await this.$services.matriculasService.matricular(this.form);
        }
        this.$toast.success("Matricula Atualizada com sucesso");
        this.$router.push({ name: "matriculas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadData() {
      this.loadFormData();

      try {
        if (
          this.matriculasList.length === 0 ||
          this.alunosList.length === 0 ||
          this.turmasList.length === 0
        ) {
          this.$loaderService.open("Carregando dados");
        }
        await Promise.all([this.listarAlunos(), this.loadTurmas()], this.listarMatriculas());
        this.$loaderService.close();
        this.$forceUpdate();
        this.loadFormData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    loadFormData() {
      const { matricula_id } = this.$route.params;

      if (matricula_id) {
        this.disabled = false;
        this.form.matriculas = this.matriculasList.filter(
          (matricula) => matricula.id === matricula_id
        );
        // this.form = { matriculas: [{}] };
        // return;
      }

      // const { matricula_id } = this.$route.params;
      // const matricula = this.matriculas.get(parseInt(matricula_id, 10));
      // if (matricula) this.form = { matriculas: [matricula] };
    },
  },
};
</script>

<style></style>
