import MapCollection from "collections/map";
import Vue from "vue";
import turmasService from "@/Services/TurmasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  turmas: [],
  carregandoTurmas: false,
  turmasLoaded: false,
  turmasList: [],
});
const mutations = {
  setTurmas(state, turmas) {
    const turmasMap = new MapCollection();
    turmas.map((turma) => turmasMap.set(turma.id, turma));
    Vue.set(state, "turmas", turmasMap);
    Vue.set(state, "turmasLoaded", true);
    Vue.set(state, "turmasList", Array.from(state.turmas.values()));
  },
  setCarregandoTurmas(state, newValue) {
    state.carregandoTurmas = newValue;
  },
  visualizarTurma(state, turma) {
    state.turmas.set(turma.id, turma);
    Vue.set(state, "turmasList", Array.from(state.turmas.values()));
    Vue.set(state, "turmas", state.turmas);
  },
  adicionarTurma(state, turma) {
    state.turmas.set(turma.id, turma);
    Vue.set(state, "turmasList", Array.from(state.turmas.values()));
    Vue.set(state, "turmas", state.turmas);
  },
  atualizarTurma(state, turma) {
    state.turmas.set(turma.id, turma);
    Vue.set(state, "turmasList", Array.from(state.turmas.values()));
    Vue.set(state, "turmas", state.turmas);
  },
  deletarTurma(state, turmaId) {
    state.turmas.delete(turmaId);
    Vue.set(state, "turmasList", Array.from(state.turmas.values()));
    Vue.set(state, "turmas", state.turmas);
  },
};
const actions = {
  async loadTurmas({ commit }, options = {}) {
    commit("setCarregandoTurmas", true);
    try {
      const turmasList = await turmasService.syncAll(options.force);
      commit("setTurmas", turmasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoTurmas", false);
  },
};
const getters = {
  turmas: (state) => state.turmas,
  carregandoTurmas: (state) => state.carregandoTurmas,
  turmasLoaded: (state) => state.turmasLoaded,
  turmasList: (state) => state.turmasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
