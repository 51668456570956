<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$services.authService.logout();
    if (this.$route.name !== "login") {
      this.$router.push({ name: "login" });
    }
  },
};
</script>

<style></style>
