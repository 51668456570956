import Axios from "@/plugins/Axios";
import store from "@/store";
import Curso from "@/Models/Curso";

export class CursosService {
  async syncAll() {
    // const cursosList = store.getters["Cursos/cursos"];
    // if (force || cursosList.length === 0) {
    const response = await Axios().get("cursos");
    const cursos = response.data.map((curso) => new Curso(curso));
    return cursos;
    // }
    // return cursosList;
  }

  async criarCurso(curso) {
    const response = await Axios().post("cursos", curso);
    const novaCurso = response.data;
    store.commit("Cursos/adicionarCurso", novaCurso);
    return novaCurso;
  }

  async atualizarCurso(curso) {
    const response = await Axios().put(`cursos/${curso.id}`, curso);
    const cursoAtualizada = response.data;
    store.commit("Cursos/atualizarCurso", cursoAtualizada);
    return cursoAtualizada;
  }

  async deletarCurso(curso) {
    const response = await Axios().delete(`cursos/${curso.id}`);
    const cursoDeletada = response.data;
    store.commit("Cursos/deletarCurso", curso.id);
    return cursoDeletada;
  }

  async recuperarCurso(curso) {
    return this.atualizarCurso({ ...curso, deleted_at: null });
  }
}
export default new CursosService();
