import MapCollection from "collections/map";
import Vue from "vue";
import matriculasService from "@/Services/MatriculasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  matriculas: [],
  carregandoMatriculas: false,
  matriculasLoaded: false,
  matriculasList: [],
});
const mutations = {
  setMatriculas(state, matriculas) {
    const matriculasMap = new MapCollection();
    matriculas.map((matricula) => matriculasMap.set(matricula.id, matricula));
    Vue.set(state, "matriculas", matriculasMap);
    Vue.set(state, "matriculasList", Array.from(matriculasMap.values()));
    Vue.set(state, "matriculasLoaded", true);
    // state = {...state, matriculas: matriculasMap, matriculasLoaded: true}
  },
  setCarregandoMatriculas(state, newValue) {
    state.carregandoMatriculas = newValue;
  },
  adicionarMatricula(state, matricula) {
    state.matriculas.set(matricula.id, matricula);
    Vue.set(state, "matriculas", state.matriculas);
    Vue.set(state, "matriculasList", Array.from(state.matriculas.values()));
  },
  atualizarMatricula(state, matricula) {
    state.matriculas.set(matricula.id, matricula);
    Vue.set(state, "matriculas", state.matriculas);
    Vue.set(state, "matriculasList", Array.from(state.matriculas.values()));
  },
  deletarMatricula(state, matriculaId) {
    state.matriculas.delete(matriculaId);
    Vue.set(state, "matriculas", state.matriculas);
    Vue.set(state, "matriculasList", Array.from(state.matriculas.values()));
  },
};
const actions = {
  async loadMatriculas({ commit }, options = {}) {
    commit("setCarregandoMatriculas", true);
    try {
      // const matriculasList = await matriculasService.syncAll(options.force);
      const matriculasList = await matriculasService.syncAllNoPaginate(options.force);
      commit("setMatriculas", matriculasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoMatriculas", false);
  },
};
const getters = {
  matriculas: (state) => state.matriculas,
  carregandoMatriculas: (state) => state.carregandoMatriculas,
  matriculasLoaded: (state) => state.matriculasLoaded,
  matriculasList: (state) => state.matriculasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
