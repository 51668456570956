<template>
  <div>
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-chip label color="error" small>{{ matricula.situacao }}</v-chip>
    </template>
    <template v-else>
      <span v-if="!mostrarResultado">- - -</span>
      <v-chip
        label
        color="warning"
        small
        v-else-if="mediaSemestral < notaMinima && recuperacao < notaMinima"
        >RECUPERAÇÃO</v-chip
      >
      <v-chip label color="success" small v-else>APROVADO</v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    media1: {
      type: [Number, String],
    },
    media2: {
      type: [Number, String],
    },
    recuperacao: {
      type: [Number, String],
    },
    notaMinima: {
      type: [Number],
      default: 7,
    },
    matricula: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaSemestral() {
      return (parseFloat(this.media1 || 0, 10) + parseFloat(this.media2 || 0, 10)) / 2.0;
    },
    mostrarResultado() {
      return (
        !this.estaVazio(this.media1) ||
        !this.estaVazio(this.media2) ||
        !this.estaVazio(this.recuperacao)
      );
    },
  },
  methods: {
    estaVazio(n) {
      return n === undefined || n === null;
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>
