<template>
  <v-menu bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip label :color="color" class="text-center" x-small v-bind="attrs" v-on="on">
        {{ descricaoSituacao }}
      </v-chip>
    </template>
    <v-list>
      <v-list-item
        v-for="situacao of $constants.situacoesUsuarios"
        :key="situacao.descricao"
        link
        @click="() => changeSituacao(situacao.descricao)"
      >
        <v-list-item-title>{{ situacao.descricao }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "EUserSituacao",
  data() {
    return {
      color: this.value.color,
      descricaoSituacao: this.value.situacao,
      atualizando: false,
    };
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async changeSituacao(novaSituacao) {
      this.atualizando = true;
      try {
        const usuarioAtualizado = await this.$services.usuariosService.atualizarSituacao(
          this.value.id,
          novaSituacao
        );
        // this.$emit("usuarioAtualizado", { usuarioAtualizado });
        this.descricaoSituacao = usuarioAtualizado.descricaoSituacao;
        this.color = usuarioAtualizado.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
  },
};
</script>
