import Vue from "vue";
import Vuetify from "vuetify/lib";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/es5/locale/pt";

import newTheme from "./themes/newTheme";

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: newTheme,
    },
    // dark: true,
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
};

export default new Vuetify(opts);
